
import './CoveragePage.css'
import { useParams } from 'react-router-dom'
import { getMainData } from '../../api/mainPage'
import { useEffect, useState } from 'react'
import { sendFormRequest } from '../../utils/requests'
import { useBalanceContext } from '../../contexts/BalanceContextProvider'
import SubmitButton from '../SubmitButton/SubmitButton'
import SocialNetworkBlock from './SocialNetworkBlock'
import { goSwitchCoverageStatus } from '../../api/mainPage'
import TelegramIcon from '../../assets/icons/tg'
import { sendRequest } from '../../utils/requests'
import coverageBackground from '../../assets/pics/coverage_background.png'
import noProfilePicture from '../../assets/pics/no_profile_photo.png'
import InfoButton from '../InfoButton/InfoButton'
import InfoPopup from '../InfoPopup/InfoPopup';
import { FormattedMessage, useIntl } from 'react-intl'
import { getAddSnData } from '../../api/addSocialNetwork'
import AddSNLeadgenBlock from '../AddSNLeadgenBlock/AddSNLeadgenBlock'
import { notification } from 'antd'
import ClipLoader from 'react-spinners/ClipLoader'
import { StatusForCoverage } from '../StatusForCoverage/StatusForCoverage'
import { CreateCoverage } from '../CreateCoverage/CreateCoverage'

export function CoveragePage() {
    const { allDataForCompanies } = useBalanceContext()
    const [accounts, setAccounts] = useState()
    const {prices, balance} = useBalanceContext()
    const {companyId} = useParams()
    const [coverageData, setCoverageData] = useState({})
    const [coverageStatus, setCoverageStatus] = useState(0)
    const [bio, setBio] = useState()
    const [name, setName] = useState()
    const [selectedProfilePicture, setSelectedProfilePicture] = useState()
    const [selectedProfilePictureUrl, setSelectedProfilePictureUrl] = useState()
    const {setNoBalancePopupOpen} = useBalanceContext()
    const [infoPopupOpen, setInfoPopupOpen] = useState(false)
    const intl = useIntl()
    const [tooltip, setTooltip] = useState(false)
    

   async function switchCoverageStatus(status) {

    setCoverageStatus(status)
    const result = await goSwitchCoverageStatus({'social_network' : 'tg',
             'cur_status' : coverageStatus}, companyId,
             ()=>setNoBalancePopupOpen(true))

    if (!result.status) {
        return
    }

    console.log(result)      
   }


   useEffect(()=>{
    async function getData(){
        const result = await getAddSnData(companyId)
        if(!result.status){
            notification.error({message : 'Произошла ошибка..'})
            return
        }
        setAccounts(result.responce.accounts)
    
    }
    getData()
}, [companyId])

   async function sendCoverageData(name, bio, photo) {

    const data = {}
    
    if (photo) {
        data['photo'] = photo
    } else {
        
    }
    data['bio'] = bio
    data['name'] = name
    data['company_id'] = companyId
    const result =  await sendFormRequest(`/db/changecoverageprofile`, 'POST', data);
    console.log(result)

   }

   function getImgSrc(){
    if (selectedProfilePicture) {
        return URL.createObjectURL(selectedProfilePicture)
    }
    return noProfilePicture
}

if (accounts == undefined) {
    return (
    <div style={{width: '100%', height: '65vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <ClipLoader color='grey'/>
    </div>)
}

    
    return (
        <div className='main_container'>
        <div className='inner_container'>
            <div className='title_container'>
                <TelegramIcon/>
                <h1 className='title'><FormattedMessage id='coverage_telegram' /></h1>
                <div style={{display: 'flex', alignItems: 'center'}}>
                <InfoButton  onInfoPopupOpen={setInfoPopupOpen} />
                
                {
                    infoPopupOpen && <div style={{backgroundColor: 'white', width: '280px', height: '60px', padding: 10, borderRadius: 11, zIndex: 10}}>
                        <p style={{fontFamily: 'Futura PT', fontSize: '1rem', lineHeight: '1.1rem'}}><FormattedMessage id='algorithm' /></p>
                    </div>
                }
                </div>
            </div>

            <div className='info_container'>
                <div className='left-container'>
                    <StatusForCoverage/>
                    <CreateCoverage/>
                </div>


                <div className='name_bio_container'>

                    <div style={{width: '100%', marginBottom: 20}}>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', position: 'relative'}}>
                            <span  onMouseEnter={() => setTooltip(true)} onMouseLeave={() => setTooltip(false)} className='link-btn' style={{borderRadius: 30, background:'rgba(0, 23, 126, 1)'}}>
                                <FormattedMessage id='coverage_how_work' />
                            </span>
                            <div style={tooltip ? {display: 'flex'} : {display: 'none'}} className='tooltip-container'>
                                <p><FormattedMessage id='coverage_how_work_text' /></p>
                            </div>
                        </div>
                    </div>
                    <input type='file' id='profile_input' style={{display: 'none'}} onChange={(event) => {
                        if(event.target.files.length != 0){
                            setSelectedProfilePicture(event.target.files[0]);
             
                        }}}/>
                    <div className='name_container'>
                    
                        <img src={getImgSrc()} alt='profile' width={'90px'} height={'90px'} style={{borderRadius: '4rem'}}  onClick={() => document.getElementById('profile_input').click()}/>
                        <input placeholder={intl.formatMessage({id: 'account_name'})} onChange={(event) => setName(event.target.value)} className='info_input' />
                        
                    </div>
                <textarea placeholder={intl.formatMessage({id: 'account_bio'})} onChange={(event) => setBio(event.target.value)} className='bio_input'/>
                <div className='CA_input_block'>
                    <div className='CA_input_block_inner'>
                        <TelegramIcon width='35' height='35'/>
                        <input className='CA_input' placeholder={intl.formatMessage({id: 'page_with_CA'})}/>
                    </div>
                    <div style={{ width: '30px', height: '30px', textAlign: 'center', background:'rgba(75, 102, 247)', borderRadius: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{fontSize: '0.8em', textAlign: 'center', color: 'white'}}>?</p>
        </div>
                </div>
                <div className='CA_input_block'>
                    <div className='CA_input_block_inner'>
                        <TelegramIcon width='35' height='35'/>
                        <input className='CA_input' placeholder={intl.formatMessage({id: 'page_with_CA'})}/>
                    </div>
                    <div style={{ width: '30px', height: '30px', textAlign: 'center', background:'rgba(75, 102, 247)', borderRadius: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{fontSize: '0.8em', textAlign: 'center', color: 'white'}}>?</p>
        </div>
                </div>

                <div className='CA_input_block'>
                    <div className='CA_input_block_inner'>
                        <TelegramIcon width='35' height='35'/>
                        <input className='CA_input' placeholder={intl.formatMessage({id: 'page_with_CA'})}/>
                    </div>
                    <div style={{ width: '30px', height: '30px', textAlign: 'center', background:'rgba(75, 102, 247)', borderRadius: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{fontSize: '0.8em', textAlign: 'center', color: 'white'}}>?</p>
        </div>
                </div>
                
                
                <button className='link-btn' >{intl.formatMessage({id: 'save'})}</button>
                </div> 



                {/* <div style={{width: '60%'}}>
                <AddSNLeadgenBlock  width='100%'  accountsData={accounts} setAccountsData={setAccounts}/>
                <SubmitButton style={{width: '100%', marginTop: 25}} clickHandler={() => sendCoverageData(name, bio, selectedProfilePicture) }  text={intl.formatMessage({id: 'save_data'})} />

                </div> */}
                
            

            </div>
          
            
               
            </div>

        


            
            
        </div>
    )
}