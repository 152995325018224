import { Link, Outlet, useParams } from 'react-router-dom';
import { Header } from '../Header/Header';
import { BalanceContextProvider } from '../../contexts/BalanceContextProvider';
import BalancePopup from './BalancePopup';

export default function BalancePopupProvider({onChangeLang}) {
    return (
        <BalanceContextProvider>
            <BalancePopup/>
            <Header onChangeLang={onChangeLang}/>
        </BalanceContextProvider>
    );
}
