import SNIcon from './SNIcon';
import './SNIcon.css';
import config from '../../constants/config.json'
import { useParams } from 'react-router-dom';
import { useBalanceContext } from '../../contexts/BalanceContextProvider';
import LeadgenDownloadIcon from '../../assets/icons/leadgen_download';
import { getPrettyNumber } from '../../utils/remakeCount';
import IconAnimated from '../Icon/IconAnimated';

const downloadIcon = require('../../assets/animatedIcons/download.json')

export default function SNIconDownload({social_network, is_green=true, value=1, unread = 0}) {
    const {companyId} = useParams()
    const getDownloadLink = ()=>{
        return `${config["api_url"]}/v1/db/getLeads/${companyId}/${social_network}/${localStorage.getItem('auth_token')}`
    }
    const {balance, setNoBalancePopupOpen, prices} = useBalanceContext()
    function goDownloadLeads(){
        console.log(prices)
        console.log(balance)
        console.log(unread)
        if(prices['lead'] && balance < unread*prices['lead']){
            setNoBalancePopupOpen(true)
            return
        }
        window.open(getDownloadLink(), '_blank')
    }
    return (
        <div className='sn_icon_dwn'>
            <div className={'sn_icon_dwn_wrap ' + (is_green ? 'green' : '')}>
                <SNIcon social_network={social_network} width="40" height="40"/>
                <div className={'sn_icon_inf ' + (is_green ? 'green' : '')}>
                    {getPrettyNumber(+value) }
                </div>
            </div>
            <button onClick={goDownloadLeads} disabled={!is_green} className='button_dwn'>
                <IconAnimated icon={downloadIcon} isNow={true} />
            </button>
        </div>
    );
}