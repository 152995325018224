import { useEffect, useState } from 'react';
import FacebookIcon from '../../assets/icons/fb';
import InstagramIcon from '../../assets/icons/inst';
import LinkedinIcon from '../../assets/icons/linkedin';
import TelegramIcon from '../../assets/icons/tg';
import TwitterIcon from '../../assets/icons/tw';
import VKIcon from '../../assets/icons/vk';
import VKGroupIcon from '../../assets/icons/vkgroup';
import WhatsappIcon from '../../assets/icons/whatsapp';
import YoutubeIcon from '../../assets/icons/yt';
import './SNIcon.css';
import React from 'react';
import SubmitButton from '../SubmitButton/SubmitButton';
import InputText from '../InputText/InputText';
export default function SNIcon({social_network, width="65", height="65"}) {
    const [IconComponent, setIconComponent] = useState(<></>)
    function getIconComponent(name){
        switch(name){
            case 'vk':
                return <VKIcon width={width} height={height}/>
            case 'tg':
                return <TelegramIcon width={width} height={height}/>
            case 'whatsapp':
                return <WhatsappIcon width={width} height={height}/>
            case 'inst':
                return <InstagramIcon width={width} height={height}/>
            case 'linkedin':
                return <LinkedinIcon width={width} height={height}/>
            case 'tw':
                return <TwitterIcon width={width} height={height}/>
            case 'fb':
                return <FacebookIcon width={width} height={height}/>
            case 'yt':
                return <YoutubeIcon width={width} height={height}/>
            default:
                return <VKGroupIcon width={width} height={height}/>
        }
    }
    useEffect(() => {
        setIconComponent(getIconComponent(social_network))
    }, [setIconComponent, social_network])
    return (
        <>
            {IconComponent}
        </>
    );
}
