import { useEffect, useState } from 'react';
import styles from './AddSNCompanyBlock.module.css'
import EditableText from '../NewPostTextEditCard/EditableText';
import { changeCompanyDesc, getCompanyDesc } from '../../api/addSocialNetwork';
import { notification } from 'antd';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {useIntl} from 'react-intl';
export default function AddSNCompanyBlock({companyDescription, setCompanyDescription, width='55%'}) {
    const { companyId } = useParams()
    const intl = useIntl()
    async function goChangeDesc(){
        const result = await changeCompanyDesc(companyId, {'description' : companyDescription})
        if(result.status){
            notification.success({
                message : "Описание обновлено!"
            })
        }else{
            notification.error({
                message : "Произошла ошибка"
            })
        }
    }   
    return (
        <div className={styles.container} style={{width: width}}>
            <div className={styles.content}>
                <p className={styles.text}>
                    <FormattedMessage id='about_company' />
                </p>
                <EditableText value={companyDescription} changeHandler={setCompanyDescription}
                 maxLength={255} saveHandler={goChangeDesc} posting={false} placeholder='Кликните чтобы ввести информацию о компании' />
            </div>
        </div>
    );
}
