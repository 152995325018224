import {sendRequest, sendFormRequest} from '../utils/requests';

export async function getMainData(data) {
    return await sendRequest(`/db/getmaindata`, 'POST', {...data});
}

export async function goSwitchCoverageStatus(data, companyId, onBalanceLack) {
    return await sendRequest(`/db/switchcoveragestatus`, 'POST', {...data, company_id : companyId}, onBalanceLack);
}

export async function getUserBalance() {
    return await sendRequest(`/db/getbalance`, 'POST');
}

export async function getCompanyName(data, companyId) {
    return await sendRequest(`/db/getcompanyname`, 'POST', {...data, company_id : companyId});
}