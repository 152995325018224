import './index.css';
import { Button,ConfigProvider,   theme ,Avatar,Space,Card, notification } from 'antd';
import React, { useEffect, useState } from "react"
import { UserOutlined,SettingOutlined  } from '@ant-design/icons'
import { AvatarButton } from '../AvatarButton/AvatarButton';
import { getCompanyName, getMainData, getUserBalance } from '../../api/mainPage';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useBalanceContext } from '../../contexts/BalanceContextProvider';
import { SplitView } from './SplitView';
import { LeftPanel } from './LeftPanel';
import SLLogo from '../../assets/icons/logo.png';
import PlusButtonIcon from '../../assets/icons/addbutton';
import ParamsAddCompany from '../ParamsAddCompany/ParamsAddCompany';
import Modal from '../Modal/Modal';
import { useIntl } from 'react-intl';
import { LOCALES } from '../../i18n/locales';


const HeaderContent = ({onChangeLang}) => {
    const [showCompanies, setShowCompanies] = useState(false)
    const [curCompanyName, setCurCompanyName] = useState('--')
    const [companiesList, setCompaniesList] = useState([])
    const {companyId} = useParams()
    const [selectedLang, setSelectedLang] = useState()
    const [langOpen, setLangOpen] = useState(false)

    const [language, setLanguage] = useState({})



    
    console.log(language)
    const lang = [
        {
            lang: LOCALES.ENGLISH,
            logo: 'ENG'
        },
        {
            lang: LOCALES.RUSSIAN,
            logo: 'RUS'
        },

    ]

    function handleLang() {
        const newLang = lang.filter(lang => lang.lang == language)
        console.log(newLang)
        setSelectedLang(newLang)
        if (!newLang) {
            return lang[0].logo
        }
        return <p>{newLang[0].logo}</p>
    }

    console.log(localStorage.getItem('lang'))

    const intl = useIntl()
    const {allDataForCompanies, setAllDataForCompanies, balance} = useBalanceContext()
    useEffect(()=> {
        let newCompaniesList = []
        for(let compId in allDataForCompanies){
            newCompaniesList.push({id : compId,
                 name: allDataForCompanies[compId]['company_name']})
        }
        setCompaniesList(newCompaniesList)
        if(allDataForCompanies[companyId])
        setCurCompanyName(allDataForCompanies[companyId]['company_name'])
    }, [companyId, allDataForCompanies])
    const location = useLocation()
    const navigate = useNavigate()
    const getUrlWithNewCompany = (newCompId) => {
        let link_splitted = location.pathname.split('/')
        link_splitted[link_splitted.length-1] = newCompId
        return link_splitted.join('/')
    }
    const newCompanySelected = (newCompId) => {
        setShowCompanies(false)
        navigate(getUrlWithNewCompany(newCompId))
    }
    const [addCompanyModalActive, setAddCompanyModalActive] = useState(false)
    function addCompanyModalClosed(data={}){
        if(data.company){
            setAllDataForCompanies({...allDataForCompanies, [data.id] : data.company})
            console.log(data)
        }
        setAddCompanyModalActive(false)
        navigate(getUrlWithNewCompany(data.id))
    }
    return (
        <React.Fragment>
            {(addCompanyModalActive ?
                <Modal title={"Добавление компании"} modalClosed={addCompanyModalClosed} ComponentContent={ParamsAddCompany}/>
            :
                <></>
            )}
            <div className='header_wrap'>
                <img onClick={() => navigate(`/main/${companyId}`)} src={SLLogo} height={40}/>
                <div style={{display: 'flex', flexDirection: 'row', gap:'10px', alignItems: 'center'}}>




                    <div className='balance_text'>
                        {parseInt(balance)/100} TKN
                    </div>
                    
                 

                    < div className='company_selector_wrap'>
                        <button onClick={()=>setShowCompanies(!showCompanies)} className='company_selector'>
                            {curCompanyName}
                            <div className='company_selector_icon'></div>
                        </button>
                        {showCompanies && 
                            <ul className='company_dropdown_menu'>
                                {companiesList.map(company => (
                                    <li className='company_option'
                                    onClick={()=>newCompanySelected(company.id)}>
                                        {company.name}
                                    </li>
                                    
                                ))}
                                
                                <li className='company_option add_option'
                                onClick={()=>{setShowCompanies(false);setAddCompanyModalActive(true)}}>
                                    {intl.formatMessage({id: 'company_add'})}
                                    <PlusButtonIcon/>
                                </li>
                            </ul>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export const Header = ({onChangeLang}) => {
    const {companyId} = useParams()
    const navigate = useNavigate()
    const {balance, setBalance, setPrices, setBonuses, setAllDataForCompanies, allDataForCompanies, setTotalBots, setLanguage, language} = useBalanceContext()
    useEffect(()=>{
        async function loadData(){
            const result = await getMainData({})
            if (!result.status){
                notification.error({'message' : "Произошла ошибка"})
            }
            if(Object.keys(result.responce.companies_data).length == 0){
                navigate('/firstcompany')
                return
            }
            setAllDataForCompanies(result.responce.companies_data)
            setBalance(result.responce.balance)
            setBonuses(result.responce.bonuses)
            setPrices(result.responce.prices)
            setTotalBots(result.responce["total_bots"])
        }
        loadData()
        const interval = setInterval(loadData, 60000);
        return ()=> clearInterval(interval)
    }, [])

    console.log('TRUE LNG', language)

    useEffect(()=> {
        if (balance !== '--' && ((!companyId) || !(companyId in allDataForCompanies))){
            navigate(`/main/${Object.keys(allDataForCompanies)[0]}`)
        }
    }, [companyId, allDataForCompanies, balance])
    return(
        <>
            <div>
                <SplitView
                right={<React.Fragment><HeaderContent onChangeLang={onChangeLang}/><Outlet/></React.Fragment>}
                color={"#00115D"}
                />
            </div>
        </>
        
        )
}