import React, { useEffect, useState } from "react"
import { Col, Row, Form, Input, Button} from 'antd';
import './Tariffs.css'
import {Switch } from 'antd';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";





export const Tariffs = () => {
    return(
        <>
            <div className="container">
                <div className="tariffs_wrap">
                    <div className="add_sn_header">
                        Выберете тарифный план
                    </div>
                    <div className="menu_wrap">
                        <div className="switchs_wrap">
                            <div className="swich_inf_wrap">
                                <Switch/>
                                <div className="text_info">Posting</div>
                            </div>
                            <div className="swich_inf_wrap">
                                <Switch/>
                                <div className="text_info">Short Posting</div>
                            </div>
                        </div>
                        <div className="sliders_wrap">
                            <div className="text_sliders">
                                <div className="text_info">Min</div>
                                <div className="text_info">Legend</div>
                                <div className="text_info">Max</div>
                            </div>
                            <Slider
                                defaultValue={3}
                                min={1}
                                max={5}
                                step ={1}

                                trackStyle={{ backgroundColor: "#C6CFFF", height: 22, borderRadius: 11 }}
                                railStyle={{ backgroundColor: "#C6CFFF", height: 22, borderRadius: 11 }}
                                handleStyle={{
                                    borderColor: "transparent",
                                    height: "20px",
                                    width: "20px",
                                    marginTop: "1px",
                                    boxShadow: "rgba(0, 35, 11, 0.2) 0px 2px 4px 0px",
                                    backgro: "#68FF92",
                                    backgroundColor: "#68FF92"
                                    
                                }}
                            />
                        </div>
                        <div className="sliders_wrap">
                            <div className="text_sliders">
                                <div className="text_info">Min</div>
                                <div className="text_info">Охваты</div>
                                <div className="text_info">Max</div>
                            </div>
                            <Slider
                                defaultValue={3}
                                min={1}
                                max={5}
                                step ={1}

                                trackStyle={{ backgroundColor: "#C6CFFF", height: 22, borderRadius: 11 }}
                                railStyle={{ backgroundColor: "#C6CFFF", height: 22,borderRadius: 11 }}
                                handleStyle={{
                                    borderColor: "transparent",
                                    height: "20px",
                                    width: "20px",
                                    marginTop: "1px",
                                    boxShadow: "rgba(0, 35, 11, 0.2) 0px 2px 4px 0px",
                                    backgro: "#68FF92",
                                    backgroundColor: "#68FF92"
                                    
                                }}
                            />
                        </div>
                        <button className="button_up" >22.300</button>

                    </div>
                </div>
            </div> 
        </>
    )
}