import './Login.css';
import CenterWindow from '../CenterWindow/CenterWindow';
import InputText from '../InputText/InputText';
import React, { useRef, useState } from 'react';
import SubmitButton from '../SubmitButton/SubmitButton';
import { getAuthToken } from '../../api/api';
import { Link, useNavigate } from 'react-router-dom';
import SignInWays from '../OtherSignInWays/SignInWays';
import UserSmallIcon from '../../assets/icons/usericon';
import LockSmallIcon from '../../assets/icons/lockicon';
import { LOCALES } from '../../i18n/locales';

import { FormattedMessage, useIntl } from 'react-intl';
export default function Login() {
  const intl = useIntl()

  const loginCredentials = useRef({})
  const [errorText, setErrorText] = useState('')
  const [waitingForLogin, setWaitingForLogin] = useState(false)
  const navigate = useNavigate()
  function changeHandler(name, new_value){
    loginCredentials.current[name] = new_value
  }
  async function submit(){
    setWaitingForLogin(true);
    const result = await getAuthToken(loginCredentials.current)
    if (!result.status){
      setErrorText(intl.formatMessage({id: 'incorrect'}))
      setWaitingForLogin(false)
      return
    }
    localStorage.setItem("auth_token", result.responce.token)

    navigate('/main')
    // setErrorText(result.token)
  }
  return (
    <React.Fragment >
        <div className='login_elements'>
          <div className='window_header'>
            <FormattedMessage id='login' />
          </div>
          
          <InputText IconComp={UserSmallIcon} placeholer={intl.formatMessage({id: 'email'})} name={'email'} changeHandler={changeHandler}
          type='email'/>
          <InputText IconComp={LockSmallIcon} placeholer={intl.formatMessage({id: 'password'})} name={'password'} changeHandler={changeHandler}
          type='password'/>
          <SubmitButton disabled={waitingForLogin} clickHandler={submit} wide style={{marginLeft: 0}}
           text={intl.formatMessage({id: 'login'})}></SubmitButton>
          {errorText !== '' ?  <div className='error_text'>{errorText}</div> : ''}
          <div>
            
              <SubmitButton text={intl.formatMessage({id: 'account_empty'})} customClassName={'red_button'} style={{marginLeft: 0}} 
              clickHandler={()=>navigate('/auth/register')} wide/>
          </div>
        </div>
    </React.Fragment>
  );
}
