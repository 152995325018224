import './RegisterCredentials.css';
import CenterWindow from '../CenterWindow/CenterWindow';
import InputText from '../InputText/InputText';
import React, { useRef, useState } from 'react';
import SubmitButton from '../SubmitButton/SubmitButton';
import { getAuthToken } from '../../api/api';
import LeadgenDownloadIcon from '../../assets/icons/leadgen_download';
import UserSmallIcon from '../../assets/icons/usericon';
import LockSmallIcon from '../../assets/icons/lockicon';
import { useIntl } from 'react-intl';


export default function RegisterCredentials({submitHandler, setErrorText}) {
  const registerCredentials = useRef({})
  const [waitingForRegister, setWaitingForRegister] = useState(false)
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const intl = useIntl()

  function changeHandler(name, new_value){
    registerCredentials.current[name] = new_value
  }
  async function submitButtonPressed(){
    if(passwordConfirm != registerCredentials.current['password']){
      setErrorText(intl.formatMessage({id: 'match_passwords'}))
      return
    }
    if(passwordConfirm.length < 2){
      setErrorText(intl.formatMessage({id: 'password_short'}))
      return
    }
    setWaitingForRegister(true)
    await submitHandler(registerCredentials.current)
    setWaitingForRegister(false) 
  }
  return (
    <React.Fragment>
        <InputText IconComp={UserSmallIcon} placeholer={intl.formatMessage({id: 'email'})} name={'email'} changeHandler={changeHandler} type='email'/>
        <InputText IconComp={LockSmallIcon} placeholer={intl.formatMessage({id: 'password'})} name={'password'} changeHandler={changeHandler} type='password'/>
        <InputText IconComp={LockSmallIcon} placeholer={intl.formatMessage({id: 'password'})} changeHandlerValue={setPasswordConfirm} type='password'/>
        <SubmitButton disabled={waitingForRegister} clickHandler={submitButtonPressed}
         text={intl.formatMessage({id: 'register'})} wide style={{marginLeft: 0}}></SubmitButton>
    </React.Fragment>
  );
}
