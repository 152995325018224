import './SNIcon.css';
import SNIcon from './SNIcon';
import { takeData } from './takeData';
import { getPrettyNumber } from '../../utils/remakeCount';


export default function SNIconInf({social_network, is_green=false, value}) {

    return (
        <div className='sn_icon'>
            <div className={'sn_icon_inf_wrap ' + (is_green ? 'green' : '')}>
                <SNIcon social_network={social_network} width="40" height="40"/>
                <div className={'sn_icon_inf ' + (is_green ? 'green' : '')}>
                    {getPrettyNumber(+value) }
                </div>
            </div>
        </div>
    );
}