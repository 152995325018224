import { Link } from 'react-router-dom';
import SNIcon from '../SNIcon/SNIcon';
import './SignInWays.css';
import { useState } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { FormattedMessage } from 'react-intl';

export default function SignInVariant({social_network, title, link}) {
    const [waitingForAuth, setWaitingForAuth] = useState(false)
    async function goSignIn(){
        if(waitingForAuth)return
        setWaitingForAuth(true)
        window.open(link, "_blank", "noreferer")
    }
    const loc = window.location.pathname
    return (
        <button className='sign_in_variant_wrap' onClick={goSignIn} >
            {!waitingForAuth || link=='' ?
            <>
            <SNIcon social_network={social_network} width='35' height='35'/>
            <div> {loc == '/auth/login' ? <FormattedMessage id='login_with' />  : <FormattedMessage id='sign_with' />} {title}</div>
            </>
            : 
            <ClipLoader color='gray'/>
            }
        </button>
    );
}
