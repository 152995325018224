import './AddSNAccountsBlock.css';
import SNIcon from '../SNIcon/SNIcon';
import Modal from '../Modal/Modal'
import { useEffect, useState } from 'react';
import allSN from '../../constants/all_social_networks.json'
import SNinfoRUS from '../../constants/social_networks_info.json'
import SNinfoGEO from '../../constants/social_networks_info_geo.json'
import SNinfoENG from '../../constants/social_networks_info_eng.json'
import ParamsForSN from '../ParamsForSN/ParamsForSN';
import SNIconWithoutInf from '../SNIcon/SNIconWithoutInf';
import { getAccounts } from '../../api/addSocialNetwork';
import { useParams } from 'react-router-dom';
import { notification } from 'antd';
import { addAccountToAccountsData } from '../../utils/accounts';
import styles from './AddSNAccountBlock.module.css'
import { FacebookIcon } from '../../assets/icons/Facebook';
import { VKIconWhite } from '../../assets/icons/VKIcon';
import { TwitterIcon } from '../../assets/icons/TwitterIcon';
import { TelegramIcon } from '../../assets/icons/TelegramIcon';
import { InstagramIcon } from '../../assets/icons/InstagramIcon';
import { FormattedMessage } from 'react-intl';
import { snackbarClasses } from '@mui/material';

export default function AddSNAccountsBlock({accountsData, setAccountsData}) {
    const [modalActive, setModalActive] = useState(false)
    const [modalSN, setModalSN] = useState(false)   
    const [SNinfo, setSNinfo] = useState()
    const lang = localStorage.getItem('lang')

    useEffect(() => {
        console.log('AM AM AM', lang)
        if ( lang != null && lang.indexOf('ka') != -1) {
            setSNinfo(SNinfoGEO)
        } else if ( lang != null && lang.indexOf('ru') != -1 ) {
            setSNinfo(SNinfoRUS)
        } else {
            setSNinfo(SNinfoENG)
        }
        console.log(SNinfo)
    }, [])
    
    function modalClosed(data){
        if(data.id){
            setAccountsData((oldData) => {
                let newData = {...oldData}
                newData[data.social_network_title] = data
                return newData
            })
        }
        setModalActive(false)
    }
    function openModal(socialNetwork){
        if(socialNetwork in accountsData){
            notification.warning({message : 'Аккаунт этой социальной сети уже добавлен'})
            return
        }
        setModalSN(socialNetwork)
        setModalActive(true)
    }
    return (
        <>
            {(modalActive ?
            <Modal title={"Добавление " + SNinfo[modalSN]['fullTitle']} 
                modalClosed={modalClosed}
                ComponentContent={ParamsForSN}
                socialNetwork={modalSN}
                SNinfo={SNinfo}
                />
            :
            <></>
            )}

            <div className={styles.container}>
                <div className={styles.content}>

                    <h1 className={styles.text}>
                        <FormattedMessage id='account_link' />
                    </h1>

                    <div style={{display: 'flex', width: '100%',
                     alignItems: 'center', padding: '5%', gap:'1rem', flexWrap: 'wrap'}}>
                        {allSN.map((title) => (
                            
                                <SNIconWithoutInf type={'settings'}  clickHandler={() => openModal(title)}
                                 social_network={title} is_green={title in accountsData}/>
                          
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
