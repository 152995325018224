import { OK } from '../../assets/icons/OK';
import GreenGrayBox from '../GreenGrayBox/GreenGrayBox';
import SNIcon from './SNIcon';
import './SNIcon.css';
import { takeData } from './takeData';


export default function SNIconWithoutInf({clickHandler=()=>{}, social_network,is_green=false,
                                             width="58", height="58", widthCircle='60', type}) {
    // var [is_green, value] = takeData(social_network, block_inf, all_social_network)

    return (
        <>
        {
            type == 'settings' ?
            <div onClick={clickHandler} className='settings_container' style={is_green ? {background: 'rgba(255, 255, 255, 0.5)'} : {background: 'rgba(138, 142, 255, 0.5)'}}>
                    {is_green && <div className='ok-icon'><OK/></div>}
                    <SNIcon social_network={social_network} width={!is_green ? widthCircle : width} height={!is_green ? widthCircle : height}/> 

            </div>
            :

            <div onClick={clickHandler}>
                <div style={is_green ? {background: 'rgba(71, 103, 252, 1)', width: widthCircle + 'px', height: widthCircle + 'px', borderRadius: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' } : {background: 'transparent'}}>
                <SNIcon social_network={social_network} width={!is_green ? widthCircle : width} height={!is_green ? widthCircle : height}/>
                </div>
            </div>
        }
            
        </>
    );
}