import './Register.css';
import CenterWindow from '../CenterWindow/CenterWindow';
import InputText from '../InputText/InputText';
import React, { useRef, useState } from 'react';
import SubmitButton from '../SubmitButton/SubmitButton';
import { getAuthToken, goCheckCode, goRegister } from '../../api/api';
import RegisterCredentials from '../RegisterCredentials/RegisterCredentials';
import RegisterVerificationCode from '../RegisterVerificationCode/RegisterVerificationCode';
import { Link, useNavigate } from 'react-router-dom';
import SignInWays from '../OtherSignInWays/SignInWays';
import { LOCALES } from '../../i18n/locales';
import { FormattedMessage, useIntl } from 'react-intl';

export default function Register() {
  const [errorText, setErrorText] = useState('')
  const [waitingForCode, setWaitingForCode] = useState(false)
  const userId = useRef(-1)
  const intl = useIntl()

  async function submitRegisterCredentials(registerCredentials){
    const result = await goRegister(registerCredentials)
    if (!result.status){
      console.log(result)
      setErrorText(result.responce || intl.formatMessage({id: 'smth_went_wrong'}))
      return
    }
    setErrorText('')
    userId.current = result.responce.user_id
    setWaitingForCode(true)
  }

  async function submitVerificationCode(verificationData){
    verificationData['user_id'] = userId.current
    const result = await goCheckCode(verificationData)
    if (!result.status){
      setErrorText("Wrong code")
      return
    }
    localStorage.setItem("auth_token", result.responce.token)
    localStorage.setItem('lang', LOCALES.ENGLISH)
    navigate('/firstcompany')
  }
  const navigate = useNavigate()
  return (
    <React.Fragment>
        <div className='window_header'>
          <FormattedMessage id='register' />
        </div>
        <div className='register_elements'>
          {( 
              !waitingForCode
              ? 
              <RegisterCredentials submitHandler={submitRegisterCredentials} setErrorText={setErrorText}/>
              :
              <RegisterVerificationCode submitHandler={submitVerificationCode}/>
          )}
          {errorText !== '' ?  <div className='error_text'>{errorText}</div> : ''}
          <SubmitButton text={intl.formatMessage({id: 'account'})} customClassName={'red_button'} 
          clickHandler={()=>navigate('/auth/login')} wide style={{marginLeft: 0}}/>
        </div>
    </React.Fragment>
  );
}
