import { useEffect, useRef, useState } from 'react';
import SNIcon from '../SNIcon/SNIcon';
import SignInVariant from './SignInVariant';
import './SignInWays.css';
import { getRegisterWithTgInitInfo, getRegisterWithTgToken, tryGetTokenTg } from '../../api/tgAuth';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export default function SignInWays() {
    const [code, setCode] = useState('')
    const [tgLink, setTgLink] = useState("")
    const navigate = useNavigate()
    const loc = window.location.pathname

    async function getRegisterWithTgToken() {
        if(!code)return
        const result = await tryGetTokenTg(code)
        if(result.status){
            const token = result.responce.token
            localStorage.setItem("auth_token", token)
            navigate('/main')
        }else{
            return null
        }
    }
    useEffect(()=>{
        async function getData(){
            const result = await getRegisterWithTgInitInfo()
            if(!result.status){
                notification.error({message: "Что-то пошло не так.."})
                return
            }
            setCode(result.responce.code)
            setTgLink(result.responce.link)
        }
        getData()
        
    }, [])
    useEffect(() => {
        getRegisterWithTgToken()
        let intervalId = setInterval(getRegisterWithTgToken, 22000)
        return () => clearInterval(intervalId)
    }, [code])
    return (
        <div className='sign_in_ways_wrap'>
            <div className='sign_in_ways_icons_wrap'>
                <SignInVariant social_network={'tg'} title={'Telegram'} link={tgLink}/>
            </div>
            {loc == '/auth/login' &&  <p onClick={() => navigate('/forgetpassword')} className='forget-text'><FormattedMessage id='forget_pass' /></p>}
        </div>
    );
}