import { useState } from 'react'
import styles from './smartTrafficPage.module.css'
import { VKIconWhite } from '../../assets/icons/VKIcon';
import { FacebookIcon } from '../../assets/icons/Facebook';
import { TwitterIcon } from '../../assets/icons/TwitterIcon';
import { TelegramIcon } from '../../assets/icons/TelegramIcon';
import { InstagramIcon } from '../../assets/icons/InstagramIcon';
import Slider from '@mui/material/Slider';
import { SocialName } from './SocialName/SocialName';
import SubmitButton from '../SubmitButton/SubmitButton';
import { ResponsiveContainer } from 'recharts';
import ClipLoader from "react-spinners/ClipLoader";
import { LineChart } from 'recharts';
import { Modal } from '@mui/material'
import { Line, XAxis, YAxis, Legend } from 'recharts';
import PropTypes from 'prop-types';
import { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { useBalanceContext } from '../../contexts/BalanceContextProvider';
import { sendRequest, sendGetRequest } from '../../utils/requests';
import { useEffect } from 'react';
import { getSocialNetworkByName } from '../../api/getSocialNetwork';
import { useAsyncError, useNavigate, useParams } from 'react-router-dom';
import { resetWarned } from 'antd/es/_util/warning';
import InfoButton from '../InfoButton/InfoButton';
import InfoPopup from '../InfoPopup/InfoPopup';
import { Progress, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import {useIntl} from 'react-intl';




  const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: 'rgba(0, 48, 255, 1)',
    height: 5,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
      height: 27,
      width: 27,
      backgroundColor: '#fff',
      border: '1px solid currentColor',
      '&:hover': {
        boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
      },
      '& .airbnb-bar': {
        height: 9,
        width: 1,
        backgroundColor: 'currentColor',
        marginLeft: 1,
        marginRight: 1,
      },
    },
    '& .MuiSlider-track': {
      height: 3,
    },
    '& .MuiSlider-rail': {
      color: 'rgba(0, 48, 255, 1)',
      opacity: theme.palette.mode === 'dark' ? undefined : 1,
      height: 3,
    },
  }));
  
  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
   
      </SliderThumb>
    );
  }
  
  AirbnbThumbComponent.propTypes = {
    children: PropTypes.node,
  };


  async function sendDataSmartTraffic(action_type, account_id, new_count) {
    const res = await sendRequest("/db/changeactivecount", 'POST', {
      action_type: "subs",
      account_id: account_id,
      new_count: new_count
    })

    if(!res.status){
      notification.error({message : 'Произошла ошибка..'})
    } else {
      notification.success({message : 'Smarttrafic включен!'})
    }

    console.log(res)

}

function createLabel(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
}



export const SmartTrafficPage = () => {

    const [selectedSN, setSelectedSN] = useState('tg')
    const [type, setType] = useState('reactions')
    const [newCount, setNewCount] = useState(0)
    const {companyId} = useParams()
    const {balance} = useBalanceContext()
    const { totalBots } = useBalanceContext()
    const { allDataForCompanies } = useBalanceContext()
    const [graphicData, setGraphicData] = useState({})
    const [socialNetworks, setSocialNetworks] = useState([])
    const [accountId, setAccountId] = useState()
    const [companyName, setCompanyName] = useState()
    const [accName, setAccName] = useState('')
    const [seoLength, setSeoLength] = useState('')
    const [infoPopupOpen, setInfoPopupOpen] = useState(false)
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [isFull, setIsFull] = useState('')
    const intl = useIntl()

    console.log(totalBots)

    const handleClose = () => setOpen(false);

    const [nowBots, setNowBots] = useState([
      {
        value: '0',
        alreadySubs: 0,
        label: '0'
      }
     
    ])

    const socials = {
      'tg' : 'Telegram',
      'vk': 'Вконтакте',
      'inst': 'Instagram',
      'tw': 'Twitter'
    }

    function handleSocialNetworks(social) {
      if (socialNetworks.includes(social)) {
        setSelectedSN(social)
      }
    }

    useEffect(() => {
      if (!allDataForCompanies[companyId]) {
  
      } else {
        console.log(allDataForCompanies)
        if (allDataForCompanies[companyId]["smart_traffic"][selectedSN]) {
          setGraphicData(allDataForCompanies[companyId]["smart_traffic"][selectedSN][type]["graphic"])
          setSocialNetworks(Object.keys(allDataForCompanies[companyId]["smart_traffic"]))
          setAccountId(allDataForCompanies[companyId]["smart_traffic"][selectedSN]["id"])
          setAccName(allDataForCompanies[companyId]["smart_traffic"][selectedSN]['shortname'])
        setSeoLength(allDataForCompanies[companyId]["smart_traffic"][selectedSN]['length'])
        setNowBots([
          {
            value: Number(allDataForCompanies[companyId]["smart_traffic"][selectedSN]['subscribes']["active_count"]),
            alreadySubs : Number(allDataForCompanies[companyId]["smart_traffic"][selectedSN]['subscribes']["done_count"]),
            label: `${Number(allDataForCompanies[companyId]["smart_traffic"][selectedSN]['subscribes']["active_count"])} ${createLabel(Number(allDataForCompanies[companyId]["smart_traffic"][selectedSN]['subscribes']["active_count"]), ['бот', 'бота', 'ботов'])}`
          }
        ]
          
        )
        console.log(companyName)
        setNewCount(allDataForCompanies[companyId]["smart_traffic"][selectedSN]['subscribes']["active_count"])
        }
        
        setCompanyName(allDataForCompanies[companyId]["company_name"])
       

        
        console.log(nowBots)
        console.log('GRAPHIC', graphicData)
        console.log(accountId)
      }
    }, [type, selectedSN, allDataForCompanies])
    
    useEffect(() => {
      if (selectedSN != 'tg') {
        setOpen(true)
      }
    }, [selectedSN])



    console.log(newCount)

    function handleNewCount(value) {
      console.log(value, nowBots)

      if (value > totalBots) {

      } else {
        setNewCount(value)
      }
    }
    


    if (!allDataForCompanies[companyId]) {
      return (
        <div style={{width: '100%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ClipLoader color='grey' />
        </div>
      )
    }

   
 

    return (
      <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{backgroundColor: 'white', width: '40%', height: '200px', padding: 15, position: 'relative', borderRadius: '11px'}}>
                <span onClick={handleClose} style={{fontSize: '2em', fontWeight: '500', cursor: 'pointer', position: 'absolute', top: 0, right: '15px'}}>&times;</span>
            
                <p style={{fontFamily: 'Futura PT', fontWeight: 500, fontSize: '1.5rem', marginTop: 30}} >Для данной соцсети SmarTraffic еще в разработке!</p>

            </div>
        
        </div>
      </Modal>

    <div style={{width: '98%', margin: 'auto', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
        <div className={styles.container}>
            <div style={{width: '48%', height: '85%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <h1 style={{color: 'rgba(5, 22, 97, 1)'}}>SmarTraffic</h1>
           
            <div style={{display: 'flex', marginBottom: '10px', gap: '10px'}}>
              <TelegramIcon color={ selectedSN === 'tg' ? 'rgba(0, 48, 255, 1)' : 'rgba(62, 80, 164, 1)'} onClick={() =>{ handleSocialNetworks('tg')}}/> 

            
                <VKIconWhite color={ selectedSN === 'vk' ? 'rgba(0, 48, 255, 1)' : 'rgba(62, 80, 164, 1)'} onClick={() =>{ handleSocialNetworks('vk')}}/> 
          
                <InstagramIcon color={ selectedSN === 'inst' ? 'rgba(0, 48, 255, 1)' : 'rgba(62, 80, 164, 1)'} onClick={() =>{ handleSocialNetworks('inst')}}/>
          
                <TwitterIcon color={ selectedSN === 'tw' ? 'rgba(0, 48, 255, 1)' : 'rgba(62, 80, 164, 1)'} onClick={() =>{ handleSocialNetworks('tw')}}/>
           
              
            </div>

            <SocialName acc_name={accName} length={seoLength} selectedSN={selectedSN}/>
          
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>

                <div className={styles.botsContainer} style={{backgroundColor: 'white'}}>
                  <Progress type="circle" percent={(nowBots[0]['alreadySubs']/nowBots[0]['value'])*100}
                   format={(percent) => `${nowBots[0]['alreadySubs']}/${nowBots[0]['value']} ${createLabel(nowBots[0]['alreadySubs'], [intl.formatMessage({id: 'bots_0'}), intl.formatMessage({id: 'bots_1'}), intl.formatMessage({id: 'bots'})])}`}
                   size={120} style={{fontSize: '2px!important'}} />
                    {/* {/* <h1>{nowBots[0]["value"]}</h1> */}
                    {/* <p>{createLabel(nowBots[0]['alreadySubs'], ['бот', 'бота', 'ботов'])}</p> */}
                </div>
                <div className={styles.botsContainer} style={{backgroundColor: 'white'}}>
                    <h1>{nowBots[0]["value"] / 10}</h1>
                    <p><FormattedMessage id='days' /></p>
                </div>

            </div>
            
            </div>

                <div style={{width: '50%'}}>
                <div className="main_block graph_block" style={{width: '100%', height: '85%'}}>
                    <div className='block_wrap'>

                        {
                          allDataForCompanies[companyId]["smart_traffic"][selectedSN]  ?
                          <div className='chart_views'>
                        <ResponsiveContainer width={'100%'} style={{fontWeight: '600'}}>
                            <LineChart  data={graphicData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                            <Line type="monotone" dataKey="total" stroke="rgb(255, 81, 0)" strokeWidth={3}/>
                            <Line type="monotone" dataKey="real" stroke="#FFF" strokeWidth={3}/>
                            {/* <CartesianGrid stroke="#" strokeDasharray="5 5" /> */}
                            <XAxis stroke='#ffffff' dataKey="da y" />
                            <YAxis stroke='#ffffff'/>
                            <Legend verticalAlign="bottom" height={10}/>
                            </LineChart>
                        </ResponsiveContainer>
                        </div>
                        :
                        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <div style={{width: '70%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                            <p style={{fontFamily: 'Futura PT', fontWeight: '500', fontSize: '1.5em', marginBottom: '3%', color: 'white'}}><FormattedMessage id='smartraffic_alert' /></p>
                            <div style={{width: '50%', margin: 'auto'}}>
                            <SubmitButton wide clickHandler={() => navigate(`/settings/${companyId}`)} text={intl.formatMessage({id:'link_account'})}></SubmitButton>
                            </div>
                          </div>
                        </div>
                        }
                        
                       
                      
                
                    
                    </div>
                </div>

                <div className={styles.containerType}>
                        <div onClick={() => setType('subscribes')} className={type === 'subscribes' ? styles.buttonOn : styles.buttonOff}> <FormattedMessage id='subscribers'/> </div>
                        <div onClick={() => setType('reactions')} className={type === 'reactions' ? styles.buttonOn : styles.buttonOff}><FormattedMessage id='likes'/></div>
                        <div onClick={() => setType('views')} className={type === 'views' ? styles.buttonOn : styles.buttonOff}><FormattedMessage id='views'/></div>
                        <div onClick={() => setType('comments')} className={type === 'comments' ? styles.buttonOn : styles.buttonOff}><FormattedMessage id='comments'/></div>
                </div>
                </div>
        </div>
        
        <p style={{fontFamily: 'Futura PT', fontWeight: '500', fontSize: '1.5em', marginTop: '1%', color: 'rgba(0, 17, 93, 1)'}}><FormattedMessage id='bots_quantity'/></p>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <input onChange={(event) => handleNewCount(Number(event.target.value))} value={newCount} className={`input`} style={{margin: 0, width: '48%'}} placeholder={intl.formatMessage({id: 'bots_quantity_input'})}/>

            <div style={{width: '47%', marginTop: '10px' }} >
                <div style={{width: '100%', justifyContent: 'space-between', display: 'flex'}}>
                  <p>0</p>
                  <p>{totalBots}</p>
                </div>
                <div className={styles.sliderContainer}>
                      <AirbnbSlider
                  valueLabelDisplay="auto"
                  slots={{ thumb: AirbnbThumbComponent }}
                  defaultValue={newCount}
                  aria-label="Restricted values"
                  
                  value={newCount}
                  max={totalBots}
                  min={0}
                  onChange={(event, value) => setNewCount(value)}
                />

                </div>
            </div>
        </div>

            <InfoPopup infoPopupOpen={infoPopupOpen} onInfoPopupOpen={setInfoPopupOpen}  title={'Образование цены'}>Цена</InfoPopup>
               
            <div className='price_block' style={{marginTop: '2%'}}>              
                <div style={{display: 'flex', width: '30%', alignItems: 'center'}}>
                  <p className='price_text' style={{width: '90%'}}><FormattedMessage id='total' />: {newCount * 10 / 100} TKN</p>
                  <InfoButton onInfoPopupOpen={setInfoPopupOpen}/>
                </div>
                <p className='balance_text'><FormattedMessage id='balance' /> {balance/100} TKN</p>
                <SubmitButton withBorder={true} text={intl.formatMessage({id: 'add_bots'})} disabled={ newCount*10/100 > balance/100 || !allDataForCompanies[companyId]["smart_traffic"][selectedSN] } customClassName='submit_button' clickHandler={() => sendDataSmartTraffic(type, accountId, newCount)}/>
            </div>  

    </div>
    </>
    )
    
    
}