import './NewPostTextEditCard.css';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

export default function EditableText({value, posting, applyStyle, editorRef, placeholder = 'Двойное нажатие для изменения текста...', changeHandler=() => {}, maxLength=null, saveHandler=()=>{}}) {
    const [isEditing , setIsEditing] = useState(false)
    const intl = useIntl()


    
    

   
    return (
        <>
         {
            posting ?  <>
        
            <div
            ref={editorRef}
            className="editable_text"
            style={{overflowY: 'scroll'}}
            contentEditable={true}
            suppressContentEditableWarning={true}
            onInput={() => {
                const text = editorRef.current.innerHTML
                changeHandler(text)
            }}
            
          ></div>
          </> :

            <div onClick={() => setIsEditing(true)} className='main_text'>
                {( isEditing && (posting == false) ? 
                    <textarea maxLength={maxLength} ref = {editorRef} autoFocus={true}
                    onBlur={() => {setIsEditing(false);saveHandler()}}
                        value={value} onChange={(e) => changeHandler(e.target.value)} 
                        className='editable_text'
                        />
                    :
                    <div className='text_for_post'>
                        {value === '' ? placeholder : value}
                    </div>
                )}
                
            </div>
         }
            
        </>
    );
}
