import { useEffect, useState } from 'react';
import SNIcon from '../SNIcon/SNIcon';
import SNIconWithoutInf from '../SNIcon/SNIconWithoutInf';
import './ChooseSocialNetworks.css';
import { getAccounts } from '../../api/addSocialNetwork';
import { useParams } from 'react-router-dom';
import { notification } from 'antd';
import { useAddPostContext } from '../../contexts/AddPostContextProvider';
export default function ChooseSocialNetworks({changeHandler=()=>{}}) {
    const {setOfSN, setSetOfSN}  = useAddPostContext()
    const {socialNetworksList, setSocialNetworksList} = useAddPostContext()
    const {companyId} = useParams()
    async function updateSNList(){
        const result = await getAccounts(companyId)
        if(!result.status){
            notification.error({
                message: 'Произошла ошибка'
            });
            return
        }
        const newList = []
        for(let account of result.responce.accounts){
            newList.push(account.social_network_title)
        }
        setSocialNetworksList(newList)
        if (newList.length == 0) {
            setSetOfSN(new Set(['0']))
        } else {
            setSetOfSN(new Set(newList))
        }
        
    }

    useEffect(()=>{
        updateSNList()
    }, []) 

    useEffect(()=>{
        changeHandler(Array.from(setOfSN))
    }, [setOfSN])

    function newSNChanged(name){
        if(setOfSN.has(name)){
            setSetOfSN(prev =>
                new Set([...prev].filter(i => i !== name))
            )
        }else{
            setSetOfSN(prev =>
                new Set([...prev, name])
            )
        }
    }
    return (
        <div className='choose_sn_wrap'>
            {socialNetworksList.map((curSN) => (
                <SNIconWithoutInf is_green={setOfSN.has(curSN)}
                 clickHandler={() => newSNChanged(curSN)}
                  social_network={curSN}
                  height='40' width='40' widthCircle='50'  key={curSN}/> 
            ))}
        </div>
  );
}
