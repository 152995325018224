import { useState, useCallback } from "react";
import { LOCALES } from "../i18n/locales";

export const useCreateBalanceContext = function(props) {
    
    //active panel's id
    const [noBalancePopupOpen, _setNoBalancePopupOpen] = useState(false)
    const setNoBalancePopupOpen = useCallback(newValue=>_setNoBalancePopupOpen(newValue), []);

    const [balance, _setBalance] = useState('--')
    const setBalance = useCallback(newValue=>_setBalance(newValue), [])

    const [prices, _setPrices] = useState({})
    const setPrices = useCallback(newValue=>_setPrices(newValue), [])

    const [bonuses, _setBonuses] = useState([])
    const setBonuses = useCallback(newValue=>_setBonuses(newValue), []) 

    const [allDataForCompanies, _setAllDataForCompanies] = useState({})
    const setAllDataForCompanies = useCallback(newValue=>_setAllDataForCompanies(newValue), []) 

    const [totalBots, _setTotalBots] = useState({})
    const setTotalBots = useCallback(newValue=>_setTotalBots(newValue), []) 
    
    const [language, _setLanguage] = useState(LOCALES.ENGLISH)
    const setLanguage = useCallback(newValue => _setLanguage(newValue), [])
    return {
        noBalancePopupOpen,
        setNoBalancePopupOpen,
        balance,
        setBalance,
        prices,
        setPrices,
        bonuses, 
        setBonuses,
        allDataForCompanies,
        setAllDataForCompanies,
        totalBots,
        setTotalBots,
        language, setLanguage
    };
  }