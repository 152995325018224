import { useNavigate, useParams } from 'react-router-dom'
import styles from './infoPopup.module.css'



export default function InfoPopup({children, title, infoPopupOpen, buttonText, buttonLink, onInfoPopupOpen, style}) {
    const navigate = useNavigate()
    const {companyId} = useParams()
    return (
        <>
        { infoPopupOpen &&
            <div className={styles.container} style={buttonText ? {background: 'white'} : {height: '150px', bottom: '120px'}}  onMouseLeave={() => onInfoPopupOpen(false)} onMouseEnter={() => onInfoPopupOpen(true)}>
                <p className={styles.title}>{title}</p>
                <p className={styles.text}>{children}</p>
                {
                    buttonText && 
                    <button onClick={()=>navigate(`/${buttonLink}/${companyId || ''}`)} className={styles.button}>{buttonText}</button>

                }
        </div>
        }
        </>
    )
}