import React, { useEffect, useState } from "react"
import { Col, Row, notification } from 'antd';
import "./MainPage.css"
import AddSNPostingBlock from "./Blocks/AddPostingBlock";
import AddLeadgenBlock from "./Blocks/AddLeadgenBlock";
import AddShortBlock from "./Blocks/AddShortBlock";
import AddCovergeBlock from "./Blocks/AddCoverageBlock";
import AddGrafBlock from "./Blocks/AddGrafBlock";
import AddBoostingBlock from "./Blocks/AddBoostingBlock";
import { getAllSocialNetwork } from "../../api/getSocialNetwork";
import { getMainData } from "../../api/mainPage";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useBalanceContext } from "../../contexts/BalanceContextProvider";

async function getSocialNetworkData() {
    const result = await getAllSocialNetwork();
    return await result.responce;
}



export const MainPage = () => {
    const {companyId} = useParams()
    const { allDataForCompanies } = useBalanceContext()
    const zeroGraphic = {}

    if (!allDataForCompanies[companyId]) {
        return (
        <div style={{width: '100%', height: '65vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ClipLoader color='grey'/>
        </div>)
    }

    return(
        <>
            <div className="grid_container">
                <div className="main_block posting_block">
                    {allDataForCompanies[companyId] ? 
                    <AddSNPostingBlock postingData={allDataForCompanies[companyId]['posting']}/>
                    :
                    <div className="spinner_wrapper center_vertical">
                        <ClipLoader color="gray"/>
                    </div>
                    }
                </div>
                <div className="main_block leadgen_block">
                    {allDataForCompanies[companyId] ? 
                    <AddLeadgenBlock leadgenData={allDataForCompanies[companyId]['leadgen']}/>
                    :
                    <div className="spinner_wrapper center_vertical">
                        <ClipLoader color="gray"/>
                    </div>
                    }
                </div>
                <div className="main_block short_block">
                    <AddShortBlock/>
                </div>
                <div className="main_block graph_block">
                {allDataForCompanies[companyId]? 
                    <AddGrafBlock graphicData = {allDataForCompanies[companyId]["smart_traffic"]['tg'] ? allDataForCompanies[companyId]["smart_traffic"]['tg']['views']["graphic"] : zeroGraphic}/>
                    :
                    <div className="spinner_wrapper center_vertical">
                        <ClipLoader color="gray"/>
                    </div>
                    }
                    
                </div>
                <div className="main_block boosting_block">
                    {allDataForCompanies[companyId] ? 
                    // <AddBoostingBlock boostingData={allDataForCompanies[companyId]['coverage']}/>
                    <AddBoostingBlock boostingData={{}}/>
                    :
                    <div className="spinner_wrapper center_vertical">
                        <ClipLoader color="gray"/>
                    </div>
                    }
                </div> 
                <div className="main_block coverage_block">
                    {allDataForCompanies[companyId] ? 
                    <AddCovergeBlock coverageDataInit={allDataForCompanies[companyId]['coverage']}/>
                    :
                    <div className="spinner_wrapper center_vertical">
                        <ClipLoader color="gray"/>
                    </div>
                    }
                </div>    
            </div>   
        </>
    )
}