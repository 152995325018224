import SNIcon from '../SNIcon/SNIcon';
import genTextIcon from '../../assets/icons/gentext.svg'
import './NewPostTextEditCard.css';
import { useEffect, useRef, useState } from 'react';
import SNinfo from '../../constants/social_networks_info.json'
import { getGptText } from '../../api/addPost';
import { notification } from 'antd';
import EditableText from './EditableText';
import ClipLoader from 'react-spinners/ClipLoader';
import { FormattedMessage, useIntl } from 'react-intl';
import VKIcon from '../../assets/icons/vk';


export default function NewPostTextEditCard({socialNetwork, postTopic, setEditedText, changeHandler=() => {}}) {
    const [isWaitingForGPT , setIsWaitingForGPT] = useState(false)
    const [text, setText] = useState("")
    const textareaRef = useRef(null)
    const editorRef = useRef()
    
    const intl = useIntl()

    const applyStyle = (style, editorRef) => {
        document.execCommand(style, false, null);
        // Получение данных в формате HTML после применения стиля
        const htmlContent = editorRef.current.innerHTML;
        setEditedText(htmlContent)
        changeHandler(editorRef.current.innerHTML)
      };


    async function goGen(){
        setIsWaitingForGPT(true)
        const result = await getGptText({
            'social_network' : SNinfo[socialNetwork]['fullTitle'],
            'post_topic' : postTopic,
            'max_length' : SNinfo[socialNetwork]['maxPostLength']
        })
        setIsWaitingForGPT(false)
        if(!result.status){
            notification.error({
                message: 'Произошла ошибка'
            })
        }
        editorRef.current.innerHTML = result.responce.text
        setText(result.responce.text)
    }
    useEffect(() => {
        changeHandler(text)
    }, [text])
    return (
        <>   
            <div className='editing_tab_wrap'>
                <div className='top_bar'>
                    <div className='top_bar_icons'>
                        <div className='icon_wrap'>
                            <SNIcon social_network={socialNetwork} width='40' height='40'/>
                        </div>
                        <button onClick={goGen} className='gen_button'>
                            { !isWaitingForGPT ? 
                                <>
                                    <img width={23} height={23} src={genTextIcon}/>
                                    <div style={{marginRight: '15px'}}><FormattedMessage id='generation' /></div>
                                </>
                            :
                            <ClipLoader size={25} color="white"/>
                            }
                        </button>
                
                        <button onClick={() => applyStyle('bold', editorRef)} className='style-button'><b>Ж</b></button>
                        <button onClick={() => applyStyle('italic', editorRef)} className='style-button'><i>К</i></button>
                    </div>
                    <div className='text_length_info' style={text.length >= SNinfo[socialNetwork]['maxPostLength'] ? {color: 'red'} :  {color: 'grey'}}>
                        <span>{text.length}/{SNinfo[socialNetwork]['maxPostLength']}</span>
                    </div>
                </div>
                <div className="App">
    </div>
                <EditableText posting={true} editorRef={editorRef} applyStyle={applyStyle} placeholder={intl.formatMessage({id: 'double_click'})} maxLength={SNinfo[socialNetwork]['maxPostLength']} value={text}
                 changeHandler={setText}/>
            </div>
        </>
    );
}
