import AddSNAccountsBlock from '../AddSNAccountsBlock/AddSNAccountsBlock';
import AddSNCompanyBlock from '../AddSNCompanyBlock/AddSNCompanyBlock';
import AddSNLeadgenBlock from '../AddSNLeadgenBlock/AddSNLeadgenBlock';
import './AddSocialNetwork.css';
import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import backArrow from '../../assets/icons/backarrow.svg'
import { getAddSnData } from '../../api/addSocialNetwork';
import { notification } from 'antd';
import SubmitButton from '../SubmitButton/SubmitButton';
import { sendRequest } from '../../utils/requests';
import { Modal } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import {useIntl} from 'react-intl';
import { LOCALES } from '../../i18n/locales';
import ClipLoader from 'react-spinners/ClipLoader';

export default function AddSocialNetwork({onChangeLang}) {
    const { companyId } = useParams()
    const [accounts, setAccounts] = useState()
    const [companyDescription, setCompanyDescription] = useState('Загрузка описания..')
    const [open, setOpen] = useState(false)
    const intl = useIntl()
    const [selectedLang, setSelectedLang] = useState()
    const [langOpen, setLangOpen] = useState(false)
    const navigate = useNavigate()

    const [language, setLanguage] = useState({})

    var lang2 = navigator.language || navigator.userLanguage; 

    function handleLang() {
        const l = localStorage.getItem('lang') || ' '

        console.log('LANG NOW', l)

        if ( l != null && l.indexOf('ru') != -1) {
            return 'RUS'
        } else if ( l != null && l.indexOf('en') != -1) {
            return 'ENG'
        } else if ( lang2 != null && lang2.indexOf('ru') != -1) {
            return 'RUS'
        } else if (l.indexOf('ka') != -1) {
            return 'GEO'
        } else if ( lang2 != null && lang2.indexOf('ka') != -1) {
            return 'GEO'
        } else return 'ENG'
    }


    const lang = [
        {
            lang: LOCALES.ENGLISH,
            logo: 'ENG'
        },
        {
            lang: LOCALES.RUSSIAN,
            logo: 'RUS'
        },
        {
            lang: LOCALES.GEORGIAN,
            logo: 'GEO'
        }

    ]




    useEffect(()=>{
        async function getData(){
            const result = await getAddSnData(companyId)
            if(!result.status){
                notification.error({message : 'Произошла ошибка..'})
                return
            }
            setAccounts(result.responce.accounts)
            setCompanyDescription(result.responce.company_description)
        }
        getData()
    }, [companyId])

    function exit() {
        localStorage.removeItem('auth_token')
        navigate('/auth/login')

    }

    

    async function deleteCompany() {
        const res = sendRequest('/db/deletecompany', 'POST', {'company_id': companyId})

    }

    async function deleteAccount() {
        const res = sendRequest('/db/deleteaccount', 'POST', {})
    }

    if (!accounts) {
        return (
        <div style={{width: '100%', height: '65vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ClipLoader color='grey'/>
        </div>)
    }

    return (
        <>
        <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{backgroundColor: 'white', padding: 15, width: '30%', height: '200px', position: 'relative', borderRadius: '11px'}}>
                <span onClick={() => setOpen(false)} style={{fontSize: '2em', fontWeight: '500', cursor: 'pointer', position: 'absolute', top: 0, right: '15px'}}>&times;</span>
                <p style={{marginTop: '2.5rem', color: 'rgba(5, 22, 97, 1)', fontFamily: 'Futura PT', fontSize: '1.5rem', fontWeight: 500}}><FormattedMessage id='delete_sure' /></p>
                <SubmitButton style={{position: 'absolute', bottom: 10, width: 'calc(100% - 30px)'}} text={intl.formatMessage({id: 'delete_sure_button'})} wide clickHandler={() => deleteAccount()}/>
            </div> 
        </div>
        </Modal>

        <div style={{width: '95%', margin: 'auto', marginTop: '5%'}}>
            <h1 style={{color: 'rgba(5, 22, 97, 1)', fontFamily: 'Futura PT'}}>
                <FormattedMessage id='settings' />
            </h1>
            
                
            <div className='row row-cols-1 mt-3'>
                <AddSNAccountsBlock accountsData={accounts} setAccountsData={setAccounts}/>
            </div>
            <div style={{display: 'flex',flexDirection: 'row', marginTop: '20px',  gap: '40px'}}>
                <div style={{width: '49%', background: 'white', height: 200, padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 30, borderRadius: 11}}>

                    <p style={{color: 'rgba(5, 22, 97, 1)', fontFamily: 'Futura PT', fontSize: '2rem', fontWeight: 500}}><FormattedMessage id='language' />:</p>

                    <div className='company_selector_wrap'>
                        <button onClick={()=>setLangOpen(!langOpen)} className='company_selector' style={{background: 'rgba(240, 242, 253, 1)'}}>
                            {handleLang()}
                            <div className='company_selector_icon'></div>
                        </button>
                        {langOpen && 
                            <ul className='company_dropdown_menu'>
                                {lang.map(lang => (
                                    <li className='company_option' onClick={() => {
                                        setSelectedLang(lang.logo)
                                        localStorage.setItem('lang', lang.lang)
                                        onChangeLang()
                                        setLangOpen(false)
                                    }}
                                    >
                                        {lang.logo}
                                    </li>
                                    
                                ))}
                            </ul>
                        }
                    </div>
                </div>

                <div style={{width: '49%', background: 'white', height: 200, padding: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 20, borderRadius: 11}}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', gap: 30 }}>
                        <button onClick={() => deleteCompany(companyId)} className='company_btn'><FormattedMessage id='delete_company' /></button>
                        <button onClick={() => setOpen(true)} className='account_btn' ><FormattedMessage id='delete_account' /></button>
                    </div>
                    <button onClick={() => exit()} className='delete_btn' ><FormattedMessage id='exit' /></button>
                </div>
                
            </div>
        </div>
        </>
    );
}
