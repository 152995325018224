import {sendRequest, sendFormRequest, sendGetRequest} from '../utils/requests';

export async function getPaymentUrl(data) {
    return await sendRequest(`/db/createpayment`, 'POST', data);
}

export async function getPaymentConfirmation(data) {
    do{
        const result = await sendRequest(`/db/waituntilpaymentdone`, 'POST', data);
        if(result.status){
            return result.responce.balance
        }
        await new Promise(resolve => setTimeout(resolve, 2000))
    } while(true);
}