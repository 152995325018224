import { useContext, createContext } from "react";
import { useCreateAddPostContext } from "./AddPostContext";
import React  from 'react';

//React context
const Context = createContext(null);

export const AddPostContextProvider = ({ children, ...props }) => {
    const context = useCreateAddPostContext(props);
    return <Context.Provider value={context}>{children}</Context.Provider>;
  };


/**
 * Custom hook in the provider lets us not to export the context
 *  and pass it every time as a parameter from the components
 *  where we will use it
 */

export function useAddPostContext() {
    const context = useContext(Context);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}