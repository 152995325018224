import { useState } from "react"
import { useNavigate } from "react-router-dom"
import SubmitButton from "../SubmitButton/SubmitButton"
import InputText from "../InputText/InputText"
import styles from './ForgetPasswordPage.module.css'

export function ForgetPasswordPage() {
    const [waitingForCode, setWaitingForCode] = useState()
    const [status, setStatus] = useState('email')

    async function handlerSendEmail() {
        setStatus('code')
       
    }

    function handlerSendCode() {
        setStatus('password')
    }

    function handlerSendPassword() {

    }

    console.log(status)
    const navigate = useNavigate()
    return (
        
        <div className={styles.container}>
            <div className={styles.content}>
                {
                    status == 'email' ?
                    <>
                    <div className='window_header'>
                    Введите email
                    </div>
                    <div className='register_elements'>
                    <InputText  name={'email'} 
            type='email'/>

                    <button className={styles.button} onClick={() => handlerSendEmail()}>
                        Отправить код
                    </button>
                </div>
                </>
                : status == 'code' ?

                    <>
                    <div className='window_header'>
                    Введите код с почты
                    </div>
                    <div className='register_elements'>
                    <InputText  name={'email'} 
            type='email'/>

                    <button className={styles.button} onClick={() => {handlerSendCode()}}>
                        Отправить
                    </button>
                </div>
                </>
                : 
                <>
                <div className='window_header'>
                Введите новый пароль
                </div>
                <div className='register_elements'>
                <InputText  name={'email'} 
        type='email'/>

                <button className={styles.button}>
                    Сохранить
                </button>
            </div>
            </>
                }

            </div>
        </div>
    )
}