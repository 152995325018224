


export default function InfoButton({onInfoPopupOpen, infoPopupOpen }) {
    return (
    <div onMouseEnter={() => onInfoPopupOpen(true)} onMouseLeave={() => onInfoPopupOpen(false)} style={{paddingRight: '15px', cursor: 'pointer'}}>
        <div style={{ width: '30px', height: '30px', textAlign: 'center', background:'rgba(75, 102, 247)', borderRadius: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{fontSize: '0.8em', textAlign: 'center', color: 'white'}}>?</p>
        </div>
    </div>
    )
}