import './NewPostPicBlock.css';
import React, { useEffect, useRef, useState } from 'react';
import noPic from '../../assets/pics/nopic.png'
import InputText from '../InputText/InputText';
import SubmitButton from '../SubmitButton/SubmitButton';
import ruRU from 'antd/locale/ru_RU'
import { DatePicker } from 'antd';
// import DateTimePicker from 'react-datetime-picker';
// import 'react-datetime-picker/dist/DateTimePicker.css';
// import 'react-calendar/dist/Calendar.css';
// import 'react-clock/dist/Clock.css';
import ChooseSocialNetworks from '../ChooseSocialNetworks/ChooseSocialNetworks';
import IconAnimated from '../Icon/IconAnimated';
import { Player } from '@lordicon/react'
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

const iconPhoto = require('../../assets/animatedIcons/photo.json')

const iconPlus = require('../../assets/animatedIcons/plus.json')

const NoPicture = ({onClick, playerRef, onMouseEnter}) => {

    return (
        <div onClick={onClick} style={{background: 'rgba(153, 172, 255, 1)', width: '100%', height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '11px'}}>
            <div onMouseEnter={() => !playerRef.current?.isPlaying ? playerRef.current?.playFromBeginning() : ''} style={{display: 'flex', alignItems: 'center'}}>
            <Player
            ref={playerRef}
            size={200}
            icon={iconPhoto}
            colorize = {'#3E50A4'}
                stroke={'bold'}
            />
      </div>
        </div>
    )
}

export default function NewPostPicBlock({datetimeChangeHandler=()=>{}, imageChangeHandler=()=>{},
                                            submitPostHandler=()=>{}, SNListChangeHandler=()=>{},
                                            }) {
    const [postDisabled, setPostDisabled] = useState(true)
    const [selectedImage, setSelectedImage] = useState(noPic);
    const [tooltip, setTooltip] = useState(false)
    const { companyId } = useParams()
    const refSetTimeout = useRef();
    const intl = useIntl()
    const navigate = useNavigate()
    function getImgSrc(){
        if(selectedImage === noPic){
            return noPic
        }
        return URL.createObjectURL(selectedImage)
    }
    const [selectedDatetime, setSelectedDatetime] = useState(new Date());
    const playerRef = useRef(null)

    function handleDateTime(date) {
        const d = new Intl.DateTimeFormat("ru", {dateStyle: "short", timeStyle: "short"}).format(date) + ':00'
        console.log(d)
        const d2 = d.slice(3, 6) + d.slice(0, 2) + d.slice(5, 21)
        console.log(d2)
        datetimeChangeHandler(d.replaceAll('.', '/'))


    }

    const onMouseEnterHandler = () => {
        refSetTimeout.current = setTimeout(() => {
          setTooltip(true);
        }, 750);
      };
    
      const onMouseLeaveHandler = () => {
        clearTimeout(refSetTimeout.current);
        setTooltip(false);
      };

    function datetimeChanged(newDt){
        setSelectedDatetime(newDt)
        const options = {hour12 : false} 
        handleDateTime(newDt)
    }

    console.log(selectedDatetime.toLocaleString())

    function SNListChanged(newList){
        if(newList.length === 0){
            setPostDisabled(true)
        }else{
            setPostDisabled(false)
        }
        SNListChangeHandler(newList)
    }

    useEffect(() => {
        datetimeChanged(selectedDatetime)
    }, [datetimeChanged, selectedDatetime])
    return (
        <div className='pic_block'>
            <div style={{display: 'flex', flexDirection: 'column', gap:'10px'}}>
            <div className='preview_pic' onMouseEnter={() => !playerRef.current?.isPlaying ? playerRef.current?.playFromBeginning() : ''}>

                {
                    selectedImage === noPic ?
                    <NoPicture playerRef={playerRef} onClick={() => document.getElementById('postImage').click()}/>
                    :
                    <img
                    onClick={() => document.getElementById('postImage').click()}
                    alt="not found"
                    width={"100%"}
                    src={getImgSrc()}/>

                }
               
                <div className="post_image_upload" >
                    <label style={{background: 'rgba(51, 71, 159, 1)', borderRadius: '50px'}} htmlFor="postImage">
                        <IconAnimated icon={iconPlus} isNow={true}/>
                    </label>
                    <input
                        type="file"
                        id="postImage"
                        onChange={(event) => {
                    
                        if(event.target.files.length != 0){
                            setSelectedImage(event.target.files[0]);
                            imageChangeHandler(event.target.files[0])
                        }}}
                    />
                </div>
            </div>
            
            <ChooseSocialNetworks socialNetworksList={['tg', 'vk']} changeHandler={SNListChanged}/>
           </div>
           <div style={{position: 'relative'}}>
            {
                tooltip && <div style={{top: '-70px', right: 80, background:'rgba(0, 48, 255, 1)', width: 200}} className='tooltip-container'>
                <p>Цена поста: 0.1 TKN</p>
                            </div>
            }
            
                <div className='bottom_buttons_block'>
        
                <DatePicker
                locale={ruRU}
                    showTime
                    onChange={(date) => setSelectedDatetime(date)}
                    showSecond={false}
                    format={'DD/MM HH:mm'}
                    placeholder={intl.formatMessage({id: 'select_date'})}
                    // value={selectedDatetime}
                />

                    <SubmitButton onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler} customClassName={'addpost_button'} disabled={postDisabled} clickHandler={submitPostHandler} text={intl.formatMessage({id: 'publishing_post'})}/>
                </div>
            </div>
            
            <SubmitButton text={intl.formatMessage({id: 'delayed_post'})} style={{background: 'blue'}} wide  clickHandler={() => navigate(`/PostponedPosts/${companyId}`)} />
        </div>
    );
}
