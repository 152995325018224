import { Button, Table, Space, notification, Popconfirm, Menu, Dropdown, Tooltip,Input,Form, Modal } from 'antd';
import {SettingOutlined} from '@ant-design/icons'
import "./Company.css"
import { useNavigate } from 'react-router-dom';

export const Company = ({company}) => {
    const navigator = useNavigate()
    return(
        <>
                <Space.Compact block onClick={() => navigator(`/MainPage/${company.id}`)}>
                    <div className='company_info' style = {{height:"48px"}}>
                        {company.title}
                    </div>
                    <button style = {{backgroundColor:"#C6CFFF",height:"48px",border:"none", borderRadius:"0px 10px 10px 0px",padding: "10px"}}><SettingOutlined  className='icon_view'/></button>
                    {/* <Button type="primary" style = {{height:"48px"}}>Изминить</Button> */}
                </Space.Compact>
        </>
    )
}