import { useEffect, useRef, useState } from 'react';
import { Player } from '@lordicon/react';
import styles from './iconAnimated.module.css'

export default function IconAnimated({icon, title, isNow, size = 34}) {
    const playerRef = useRef(null);
  
    return (
      <div onMouseEnter={() => !playerRef.current?.isPlaying ? playerRef.current?.playFromBeginning() : ''} style={{display: 'flex', alignItems: 'center'}}>
        <Player
          ref={playerRef}
          size={size}
          icon={icon}
          colorize = {isNow ? 'white' : '#3E50A4'}
            stroke={'bold'}
        />
        {
          title && <p style={{color: isNow ? 'white' : '#3E50A4'}} className={styles.text}>{title}</p>
        }
        
      </div>
    );
  }