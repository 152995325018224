import { Link, useParams } from 'react-router-dom';
import Modal from '../Modal/Modal';
import { useBalanceContext } from '../../contexts/BalanceContextProvider';
import React from 'react';
import BalancePopupContent from './BalancePopupContent';
import { useIntl } from 'react-intl';

export default function BalancePopup({}) {
    const {noBalancePopupOpen, setNoBalancePopupOpen} = useBalanceContext()
    const intl = useIntl()

    return (
        <React.Fragment>
            {(noBalancePopupOpen) &&
             (<Modal title={intl.formatMessage({id: 'add_balance'})} 
             ComponentContent={BalancePopupContent} 
             modalClosed={()=>setNoBalancePopupOpen(false)}/>)}
        </React.Fragment>
    );
}
