import CenterWindow from '../CenterWindow/CenterWindow';
import { Link, Outlet } from 'react-router-dom';
import SignInWays from '../OtherSignInWays/SignInWays';

export default function AuthBase() {
  return (
    <CenterWindow>
        <Outlet/>
        <SignInWays/>
      
    </CenterWindow>

  );
}
