import { useEffect, useState } from 'react'
import styles from './SocialName.module.css'
import { configConsumerProps } from 'antd/es/config-provider'
import { FormattedMessage } from 'react-intl';


function nameToObject(str) {
    const obj = {}
    for (let i = 0; i < str.length; i++) {
        obj[i] = str.charAt(i)
      }

    console.log(obj)
    return obj
}

export const SocialName = ({acc_name, length, selectedSN}) => {



    const [name, setName] = useState(nameToObject('TEST'))
    const [numOfLetters, setNumOfLetters] = useState(2)

    useEffect(()=>{
        setName(acc_name)
        setNumOfLetters(length)
    }, [acc_name, length, selectedSN])



    return (
        <div className={styles.container}>
            <div className={styles.containerText}>
                <div className={styles.containerName}>
                { name &&
                    Object.keys(name).map(key => (
                        <p className={key < Object.keys(name).length - numOfLetters ? styles.nameBlue : styles.nameOrange } >{name[key]}</p>
                    ))
                }
                </div>
                
                <p className={styles.text}><FormattedMessage id='smart_string'/></p>
            </div>
            <div className={styles.icon}>
                    -{numOfLetters}
                </div>

        </div>
    )
}