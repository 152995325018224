import { notification } from 'antd';
import config from '../constants/config.json'

export async function sendRequest(address, method, body, onBalanceLack) {
    const response = await fetch(config["api_url"] + "/v1" + address, {
        method,
        headers: {
            'Content-type': 'application/json',
            "secret_key" : "" + localStorage.getItem('auth_token')
        },
        body : JSON.stringify({...body})
    });
    if(response.ok) {
        const response_json = await response.json();
        if(!response_json.status){
            if(response_json.responce === "UNAUTHORIZED"){
                window.location.replace('/auth/login')
                return {catched : true}
            }
            if(response_json.responce === "Not enough tokens"){
                await onBalanceLack()
                return {catched : true}
            }
        }
        return response_json
    }
    const message = response.text;
    notification.error({
        message: 'Произошла ошибка',
        description: message
    });
    return {}
}

export async function sendGetRequest(address, method, onBalanceLack) {
    const response = await fetch(config["api_url"] + "/v1" + address, {
        method,
        headers: {
            "secret_key" : "" + localStorage.getItem('auth_token')
        }
    });
    if(response.ok) {
        const response_json = await response.json();
        if(!response_json.status){
            if(response_json.responce === "UNAUTHORIZED"){
                window.location.replace('/auth/login')
                return {catched : true}
            }
            if(response_json.responce === "Not enough tokens"){
                await onBalanceLack()
                return {catched : true}
            }
        }
        return response_json
    }
    const message = response.text;
    notification.error({
        message: 'Произошла ошибка',
        description: message
    });
    return {}
}

export async function sendFormRequest(address, method, body, onBalanceLack) {
    let formData = new FormData();
    for(let i in body){
        // const cur = {...body[i]}
        // if(cur)
        formData.append(i, body[i])
        console.log(i + " " + body[i])
    }
    const response = await fetch(config["api_url"] + "/v1" + address, {
        method,
        headers: {
            "secret_key" : "" + localStorage.getItem('auth_token')
        },
        body : formData
    });
    if(response.ok) {
        const response_json = await response.json();
        if(!response_json.status){
            if(response_json.responce === "UNAUTHORIZED"){
                window.location.replace('/auth/login')
                return {catched : true}
            }
            if(response_json.responce === "Not enough tokens"){
                await onBalanceLack()
                return {catched : true}
            }
        }
        return response_json
    }
    const message = response.text;
    notification.error({
        message: 'Произошла ошибка',
        description: message
    });
    return {}
}