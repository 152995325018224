import { Company } from "../Company/Company"
import { Button, Table, Space, notification, Popconfirm, Menu, Dropdown, Tooltip,Input,Form } from 'antd';
import "./MyCompanys.css"
import { useEffect, useState } from "react";
import ParamsAddCompany from "../ParamsAddCompany/ParamsAddCompany";
import Modal from "../Modal/Modal";
import ClipLoader from "react-spinners/ClipLoader";
import { getCompanies } from "../../api/companies";

export default function MyCompanys({}) {
    const [companiesList, setCompaniesList] = useState([])
    const [companiesLoading, setCompaniesLoading] = useState(true)
    const [addCompanyModalActive, setAddCompanyModalActive] = useState(false)
    function addCompanyModalClosed(data={}){
        if(data.title){
            setCompaniesList([...companiesList, data])
            console.log(data)
        }
        setAddCompanyModalActive(false)
    }
    async function updateCompaniesList(){
        let cl;
        try{
            cl = (await getCompanies()).responce.companies
            setCompaniesList(cl)
        }
        catch{
            console.log("companies loading error")
        }
        console.log(cl)
        setCompaniesLoading(false)
    }
    useEffect(() => {
        updateCompaniesList()
    }, [setCompaniesList, setCompaniesLoading])
    return (
        <>
            {(addCompanyModalActive ?
                <Modal title={"Добавление компании"} modalClosed={addCompanyModalClosed} ComponentContent={ParamsAddCompany}/>
            :
                <></>
            )}
            <div className='container'>
                <h1 className='add_sn_header'>
                    Ваши компании
                </h1>
                { companiesLoading ?
                    <div className="spinner_wrapper">
                        <ClipLoader  color="gray"/>
                    </div>
                :
                <>
                    <div className="list_companys">
                        {companiesList.map((a, ind) =><Company key={ind} company={a}></Company>)}
                    </div>
                    <Button onClick={() => setAddCompanyModalActive(true)} className = "btn_sub" type = "primary">Добавить</Button>
                </>
                }
            </div>
        </>
    )
}