import config from '../constants/config.json'


export async function getAuthToken(loginCredentials){
    
    const response = await fetch(config["api_url"] + '/v1/db/auth/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(loginCredentials)
    })

    return await response.json()
}


export async function goRegister(registerCredentials){
    
    const response = await fetch(config["api_url"] + '/v1/db/register/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(registerCredentials)
    })

    return await response.json()
}

export async function goCheckCode(verificationData){
    
    const response = await fetch(config["api_url"] + '/v1/db/verify/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(verificationData)
    })

    return await response.json()
}
