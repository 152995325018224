import AddSNAccountsBlock from '../AddSNAccountsBlock/AddSNAccountsBlock';
import AddSNCompanyBlock from '../AddSNCompanyBlock/AddSNCompanyBlock';
import AddSNLeadgenBlock from '../AddSNLeadgenBlock/AddSNLeadgenBlock';
import NewPostTextEditCard from '../NewPostTextEditCard/NewPostTextEditCard';
import SNIcon from '../SNIcon/SNIcon';
import './AddPostMenu.css';
import { useRef, useState } from 'react';
import backArrow from '../../assets/icons/backarrow.svg'
import InputText from '../InputText/InputText';
import SubmitButton from '../SubmitButton/SubmitButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import NewPostPicBlock from '../NewPostPicBlock/NewPostPicBlock';
import { addPost } from '../../api/addPost';
import { notification } from 'antd';
import { useAddPostContext } from '../../contexts/AddPostContextProvider';
import SNinfo from '../../constants/social_networks_info.json'
import { useBalanceContext } from '../../contexts/BalanceContextProvider';
import PenSmallIcon from '../../assets/icons/penicon';
import { FormattedMessage, useIntl } from 'react-intl';
import ClipLoader from 'react-spinners/ClipLoader';


const editIcon = require('../../assets/animatedIcons/edit.json')

export default function AddPostMenu({}) {
    const intl = useIntl()
    let {companyId} = useParams()
    const {setNoBalancePopupOpen, setBalance} = useBalanceContext()
    const [postTopic, setPostTopic] = useState("a")
    const [editedText, setEditedText] = useState()
    console.log(editedText)
    const {setOfSN} = useAddPostContext()
    const params = useRef({social_networks : ['tg'], texts : {}})
    const navigate = useNavigate()

    function paramChanged(name, newValue){
        params.current[name] = newValue
    }
    function textChanged(sn, newValue){
        params.current['texts'][sn] = newValue
    }

    console.log(params.current)

    function handleDateTime(date) {
        const d = date.slice(3, 6) + date.slice(0, 2) + date.slice(5, 21)
        const d2 = d.replaceAll('.', '/')
        return d2
    }

    async function goPublish(){

     

        console.log(params.current) 
        
        const result = await addPost(companyId, params.current,
             ()=>setNoBalancePopupOpen(true))
        if(!result.status){
            if(!result.catched)
            notification.error({
                message: 'Произошла ошибка'
            });
        }else{
            notification.success({
                message : "Опубликовано"
            })
            setBalance(result.responce.balance)
        }
    }

    if (!setOfSN) {
        return (
        <div style={{width: '100%', height: '65vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ClipLoader color='grey'/>
        </div>)
    }


    console.log(Array.from(setOfSN), Array.from(setOfSN).indexOf('0'))

    return (
        <>
        <div className='container-fluid' style={{height: '100%', flexGrow:'1', display: 'flex'}}>
            <div className='row row-cols-lg-2 g-3 row-cols-1 main_wrap' style={{flexGrow:'1', paddingBottom:'3vh'}}>
                <div className="left_column col col-lg-7 order-2 order-lg-1">
                    
                    <InputText animatedIcon={editIcon}  wide placeholer={intl.formatMessage({id: 'theme_of_post'})}
                    
                     changeHandlerValue={setPostTopic} customClassname={'blueinput'}/>
                     <div className='post_cards_block'>
                        <div className='post_cards_wrap'>

                            {Array.from(setOfSN).length !=0 && Array.from(setOfSN).indexOf('0') == -1 && Array.from(setOfSN).map((sn) => (
                                <NewPostTextEditCard
                                changeHandler={(val)=>textChanged(sn, val)}
                                setEditedText={setEditedText}
                                socialNetwork={sn} key={sn}ß
                                postTopic={postTopic}/>
                            ))}

                            {Array.from(setOfSN).indexOf('0') != -1 &&
                            <div className='no-accs-container'>
                                <p><FormattedMessage id='acc_empty' /></p>
                                <SubmitButton clickHandler={() => navigate(`/settings/${companyId}`)} text={intl.formatMessage({id: 'link_account'})} />
                            </div>

                            }
                        </div>
                    </div>
                    
                </div>
                <div className='col col-lg-5 order-1 order-lg-2'>
                    <NewPostPicBlock submitPostHandler={goPublish}
                        imageChangeHandler={(val)=>paramChanged('image', val)}
                        datetimeChangeHandler={(val)=> paramChanged('post_datetime', handleDateTime(val))}
                        SNListChangeHandler={(val)=>paramChanged('social_networks', val)}/>
                </div>
            </div>
        </div>
        </>
    );
}
