import './AddSNLeadgenBlock.css';
import SNIconBlockInf from '../../SNIcon/SNIconBlockInf';
import { FormattedMessage } from 'react-intl';

export default function AddShortBlock({all_social_network}) {
    return (
        <div className='block_wrap'>
            <div className='block_name'>
                Shorts
            </div>
            < div className='block_cards_shorts'>
                <span><FormattedMessage id='in_development' /></span>
            </div>
        </div>
    );
}