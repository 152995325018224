import { LeadgenCard } from './LeadgenCard/LeadgenCard'
import styles from './leadgenPage.module.css'
import { useBalanceContext } from '../../contexts/BalanceContextProvider'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import GreenGrayBox from '../GreenGrayBox/GreenGrayBox'
import IconAnimated from '../Icon/IconAnimated'
import { Modal } from '@mui/material'
import SubmitButton from '../SubmitButton/SubmitButton'
import { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { Slider } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEffect } from 'react'
import { notification } from 'antd'
import { getAddSnData } from '../../api/addSocialNetwork'
import AddSNCompanyBlock from '../AddSNCompanyBlock/AddSNCompanyBlock'
import ClipLoader from 'react-spinners/ClipLoader'
import { AddInputs } from './AddInputs/AddInputs'

const needSN = ['tg', 'vk']



const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: 'rgba(0, 48, 255, 1)',
    height: 5,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
      height: 27,
      width: 27,
      backgroundColor: '#fff',
      border: '1px solid currentColor',
      '&:hover': {
        boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
      },
      '& .airbnb-bar': {
        height: 9,
        width: 1,
        backgroundColor: 'currentColor',
        marginLeft: 1,
        marginRight: 1,
      },
    },
    '& .MuiSlider-track': {
      height: 3,
    },
    '& .MuiSlider-rail': {
      color: 'rgba(0, 48, 255, 1)',
      opacity: theme.palette.mode === 'dark' ? undefined : 1,
      height: 3,
    },
  }));


export const LeadgenPage = () => {
    const {companyId} = useParams()
    const { allDataForCompanies, balance } = useBalanceContext()

    const leadgenData = allDataForCompanies[companyId] ? allDataForCompanies[companyId]['leadgen'] : null
    const [type, setType] = useState('vip')
    const [countryType, setCountryType] = useState('dm')
    const [productDescription, setProductDescription] = useState('')
    const [productValue, setProductValue] = useState('')
    const [ispProfile, setIspProfile] = useState('')
    const [typeText, setTypeText] = useState('')
    const [nowLeads, setNowLeads] = useState(0)
    const [mails, setMails] = useState('yes')
    const [open, setOpen] = useState(false);
    const [price, setPrice] = useState(0)
    const [accounts, setAccounts] = useState({})
    const [companyDescription, setCompanyDescription] = useState('Загрузка описания..')
    const intl = useIntl()

    console.log(leadgenData)

  const handleClose = () => setOpen(false);

  useEffect(()=>{
    async function getData(){
        const result = await getAddSnData(companyId)
        if(!result.status){
            notification.error({message : 'Произошла ошибка..'})
            return
        }
        setAccounts(result.responce.accounts)
        setCompanyDescription(result.responce.company_description)
    }
    getData()
}, [companyId])

    useEffect(() => {

    }, [type])
 
  const countries = {
    'dm' : intl.formatMessage({id: 'doesnt_matter'}),
    'russia' : intl.formatMessage({id: 'russia'}),
    'europe' : intl.formatMessage({id: 'europe'}),
    'kz' : intl.formatMessage({id: 'kazakhstan'}),
    'usa': intl.formatMessage({id: 'usa'}),
    'south usa': intl.formatMessage({id: 'south_usa'}),
    
}



    return (
        <>
         <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{backgroundColor: 'white', width: '40%', height: '200px', padding: 15, position: 'relative', borderRadius: '11px'}}>
                <span onClick={handleClose} style={{fontSize: '2em', fontWeight: '500', cursor: 'pointer', position: 'absolute', top: 0, right: '15px'}}>&times;</span>
            
            {typeText == 'product' && <><p>Analyzes the activity of current and potential customers in the network and determines who you should write to right now to maximize the probability of closing a deal; </p>
<p>•⁠  ⁠allows you to track such signals and intents as an employee's change of position or company, news about new product launches or investments, keyword mentions, and many others;</p>
<p>•⁠  ⁠enables you to increase sales by 40-60% without increasing your marketing budget and prevent customer churn.</p>
</>}

{typeText == 'product value' && <p style={{marginTop: '2rem'}}>Increases b2b sales with AI</p>}
{typeText=='isp' && <p style={{marginTop: '2rem'}}>Any C-level in Tech, SaaS, Fintech, Finance, Edtech, Education</p>}

            </div>
        
        </div>
      </Modal>
        <div className={styles.container}>
            <div className={styles.topContainer}>
                <h1 className={styles.title}><FormattedMessage id='leadgene' /></h1>
                
                <div className={styles.choseBlock}>
                    <div className={ type === 'обычный' ? styles.choseButton : styles.button} onClick={() => setType('обычный')}>
                        <p><FormattedMessage id='usual' /></p>
                    </div>
                    <div className={ type === 'vip' ? styles.choseButton : styles.button} onClick={() => setType('vip')}>
                        <p><FormattedMessage id='leadgene' /></p>
                    </div>
                </div>
            </div>

            {
                type === 'обычный' && leadgenData && type != 'vip' ?
                <div>
                <p style={{color: 'rgba(5, 22, 97, 1)', fontSize: '1.5rem', fontWeight: 500}}><FormattedMessage id={'start_lead_generation'} /></p>
                         
                <div style={{display: 'flex', justifyContent: 'space-between'}}>

                        <div style={{width: '55%'}}>
        
                                    <AddSNCompanyBlock width='100%' companyDescription={companyDescription}
                                        setCompanyDescription={setCompanyDescription}/>
                                
                        </div>    
                            <div className={styles.cardsContainer}>
                            {needSN.map(sn=><LeadgenCard social_network={sn}
                                                is_green={leadgenData[sn]['total']} value={leadgenData[sn]['total']}
                                                unread={leadgenData[sn]['unread']}
                                                />)}


                            </div>
                    
                </div>
                </div>
            : type == 'vip' ? null :
            
            <div style={{width: '100%', height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ClipLoader color='grey' /> 
            </div>
            }

            { type == 'vip' &&
            <div>
                <div className={styles.vipContainer}>
                    {
                        Object.keys(countries).map(country => 
                            <GreenGrayBox is_green={countryType === country} clickHandler={() => setCountryType(country)} styles={{maxWidth : "15%",
                            width: "100%", textAlign : "center"}} >
                                {countries[country]}
                            </GreenGrayBox>
                        )
                    }

                </div>
                <div className={styles.inputsVipContainer}> 
                    <div className={styles.inputContainer}>
                        <p><FormattedMessage id='product' /></p>
                        <textarea onChange={(event) => setProductDescription(event.target.value) } className={styles.input} style={{height: '60px'}} placeholder={intl.formatMessage({id: 'product_placeholder'})}/>

                        <button className={styles.exampleButton} onClick={() => {
                            setTypeText('product')
                            setOpen(true)
                        }}><FormattedMessage id='example' /></button>

                    </div>
                    <div className={styles.inputContainer}>
                        <p><FormattedMessage id='value_product' /></p>
                        <textarea onChange={(event) => setProductValue(event.target.value)} className={styles.input} style={{height: '60px'}} placeholder={intl.formatMessage({id: 'value_product_placeholder'})}/>

                        <button className={styles.exampleButton}  onClick={() => {
                            setTypeText('product value')
                            setOpen(true)
                        }} ><FormattedMessage id='example' /></button>
                    </div>
                    <div className={styles.inputContainer}>
                        <p>ICP Profile</p>

                        <div className={styles.icpInputsContainer}>
                            <AddInputs/>
                        </div>
                        
                        <button className={styles.exampleButton}  onClick={() => {
                            setTypeText('isp')
                            setOpen(true)
                        }}><FormattedMessage id='example' /></button>
                    </div>
                </div>

                <div style={{width: '90%', display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{width: '65%'}}>
                    <p style={{fontWeight: 600, color: 'rgba(5, 22, 97, 1)'}}><FormattedMessage id='leads_quantity' /></p>
                    <AirbnbSlider
            valueLabelDisplay="auto"
            aria-label="Restricted values"
            max={1000}
            min={0}
            onChange={(led) => {
                console.log(led)
                setNowLeads(led.target.value)}}

        
        />
        </div>
        <div style={{width: '30%'}}>
                <p style={{fontWeight: 600, color: 'rgba(5, 22, 97, 1)'}}><FormattedMessage id='email_required' /></p>
                <div className={styles.choseBlock} style={{width: '100%'}}>
                    <div className={ mails === 'yes' ? styles.choseButton : styles.button} onClick={() => setMails('yes')}>
                        <p><FormattedMessage id='yes' /></p>
                    </div>
                    <div className={ mails === 'no' ? styles.choseButton : styles.button} onClick={() => setMails('no')}>
                        <p><FormattedMessage id='no' /></p>
                    </div>
                </div>

                </div>
                </div>

                <div className='price_block' style={{marginTop: '2%', width: '90%'}}>              
                <div style={{display: 'flex', width: '30%', alignItems: 'center'}}>
                  <p className='price_text' style={{width: '90%'}}><FormattedMessage id='total' />: {nowLeads * 20 / 100} TKN</p>
                
                </div>
                <p className='balance_text'><FormattedMessage id='balance' />: {balance/100} TKN</p>
                <SubmitButton withBorder={true}  disabled={nowLeads * 20 / 100 > balance /100} text={intl.formatMessage({id: 'run'})} customClassName='submit_button'/>
            </div>  
            </div>
            
            
        }
        </div>
        <p style={{fontFamily: 'Futura PT', fontSize: '0.9rem', position: 'absolute', bottom: 10, marginLeft: '2rem', fontWeight: 500, color: 'grey'}}>Leadgene не является продуктом SociaLite. Это партнерская интеграция</p>
      </> 
    )
}