import { LOCALES } from './locales'

export const messages = {
    [LOCALES.ENGLISH]: {
        // main page  
    posting: 'Posting',
    leadgene: 'Leadgene',
    dynamics_of_views: 'Views Dynamics',
    coverage: 'Coverage',
    boosting: 'Boosting',
    home_page: 'Home',
    balance: 'Balance',
    

    //comapny add 
    company_add: 'Add company',
    company_name: 'Enter company name...',

    // posting
    theme_of_post: 'Write the post topic to use AI',
    publishing_post: 'Publishing post',
    delayed_post_button: 'View delayed posts',
    double_click: 'Click to change text...',
    generation: 'AI',
    select_date: 'Select date',

    // delayed posts
    delayed_post: 'Delayed posts',
    select_social_network: 'Select social network',
    post: 'Publish',
    delete: 'Delete',
    empty_post: 'You don`t have delayed posts',

    // boosting
    subscribers: 'Subscribers',
    likes: 'Likes',
    view: 'Views',
    comments: 'Comments',
    post_link: 'Enter post link...',
    post_quantity: 'Enter quantity or specify on the slider:',
    post_quantity_input: 'Enter quantity',

    //smartraffic 
    smart_string: 'After this string in search you will be in 1st place',
    bots: 'Bots',
    bots_0: 'Bots',
    bots_1: 'Bots',
    days: 'Days',
    views: 'Views',
    bots_quantity: 'Enter the required number of bots or specify on the slider:',
    bots_quantity_input: 'Enter the required number of bots',
    smarttraffic_alert: 'To work with SmarTraffic, you need to link your account',
    add_social_network: 'Add social network',

    // coverage
    coverage_telegram: 'Coverage on Telegram',
    account_name: 'Account name',
    account_bio: 'Account bio',
    save_data: 'Save',
    coverage_on: 'Coverage enabled',
    coverage_off: 'Coverage disabled',
    on: 'on',
    off: 'off',
    exit: 'Log Out',

    // leadgen 
    russia: 'Russia',
    europe: 'Europe',
    kazakhstan: 'Kazakhstan',
    usa: 'USA',
    south_usa: 'South USA',
    doesnt_matter: "Doesn't matter",
    product: 'Product',
    product_placeholder: 'Description of the company product for which we are looking for people to apply solutions.',
    value_product: 'Product value',
    value_product_placeholder: 'The value of the product that solves a specific business problem.',
    icp_profile: 'ICP Profile',
    icp_profile_example: 'Profile of people who can make a decision to purchase the company product.',
    example: 'Example',
    leads_quantity: 'Enter the number of leads',
    email_required: 'Emails required?',
    yes: 'Yes',
    no: 'No',
    download: 'Download',
    usual: 'Usual',

    // balance 
    balance_top: 'Balance top-up',
    sum: 'Amount',
    tkn: 'TKN',
    bonus: 'Bonus',
    enter_email: 'Enter email',
    go_to_payment: 'Go to payment',
    use: 'Use',
    money: 'usd',

    // settings
    settings: 'Settings',
    about_company: 'About your company:',
    about_company_input: 'Description of your company',
    coverage_target_audience: 'Target Audience Page',
    account_link: 'Link account',
    delete_account: 'Delete account',
    delete_company: 'Delete company',
    inctruction_vk: 'Use the button in the menu and select "Kate Mobile", then copy the link where you will be redirected and save it in the text field below',
    delete_sure: 'Are you sure you wnat to delete your account?',
    delete_sure_button: 'Yes, delete my account',
    save: 'Save',

    total: 'Total',
    balance: 'Balance',
    add_bots: 'Add bots',
    boost: 'Boost',
    top_up_balance: 'Top up balance',
    run: 'Run',
    in_development: 'In development',
   


    //auth
    login: 'Login',
    register: 'Sign Up',
    password: 'Password',
    email: 'Email',
    sign_with: 'Sign in with',
    verification_code: 'Verification Code',
    account_empty: 'I don`t have an account',
    account: 'I have an account',
    register_button: 'Sign up',
    incorrect: 'Incorrect login or password',
    smth_went_wrong: 'Something went wrong...',
    password_short: 'The password is too short',
    match_passwords: 'passwords don`t match',
    acc_empty: 'To make your first post, add a social network',
    acc_already: 'You already have an account on this social network',


    transaction_automatic: 'Automatic debiting of the operation',
    transaction_history: 'Transaction history',

    posting_transaction: 'Write-off for Posting operation',
    boosting_trancation: 'boosting',
    smartraffic_transaction: 'SmarTraffic',
    coverage_transaction: 'coverage',
    balance_transaction: 'Replenishment of the balance',
    coverage_quantity: 'Number of reaches',
    page_with_CA: 'Page with the target audience',

    sort: 'Sort',
    sort_date: 'Sorting by date',
    sort_amount: 'Sorting by amount',
    link_account: 'Link account',
    smartraffic_alert: 'To work with SmarTraffic, you need to link an account',
    language: 'Language',
    forget_pass: 'Forget password?',
    login_with: 'Login with',

    posting_text: 'Number of posts published using the platform',
go_to_posting: 'Go to posting',
coverage_text: 'Number of people who saw your account',
go_to_coverage: 'Go to coverage',
boosting_text: 'Growth of your audience',
go_to_boosting: 'Go to boosting',
leadgen_text: 'Number of leads found',
go_to_leadgen: 'Go to lead generation',
coverage_how_work_text: 'Come up with a name, write a bio, and add a user avatar. This user is a bot that will view stories, increasing your coverage',
coverage_how_work: 'How does it work?',

add_vk_text: 'Use the button in the menu to go to "Kate Mobile", then copy the link where you are redirected and save it in the text field below',
scan_QR: 'Or scan the QR code',

start_lead_generation: 'To start lead generation, provide a company description:',
algorithm: 'Algorithm of intentional page viewing for people in your target audience',
stop: 'Stop',
status: 'Status'




  },

    [LOCALES.RUSSIAN]: {

      algorithm: 'Алгоритм намеренного просмотра вашей страницы людям с вашей ЦА',
      page_with_CA: 'Cтраница с ЦА',

      // main page  
      posting: 'Постинг',
      leadgene: 'Leadgene',
      dynamics_of_views: 'Динамика просмотров',
      coverage: 'Охваты',
      boosting: 'Накрутка',
      home_page: 'Главная',
      balance: 'Баланс',

      //company add
      company_add: 'Добавить компанию',
      company_name: 'Введите название компании',

      // posting
      theme_of_post: 'Добавьте описание поста для использования AI',
      publishing_post: 'Публикация поста',
      delayed_post_button: 'Посмотреть отложенные посты',
      double_click: 'Нажмите для изменения текста...',
      generation: 'AI',
      select_date: 'Выберите дату',

      // delayed posts
      delayed_post: 'Отложенные посты',
      select_social_network: 'Выберите соц. сеть',
      post: 'Выложить',
      delete: 'Удалить',
      empty_post: 'У вас нет отложенных постов',

      // boosting
      subscribers: 'Подписчики',
      likes: 'Лайки',
      view: 'Просмотры',
      comments: 'Комментарии',
      post_link: 'Вставьте ссылку на пост...',
      post_quantity: 'Введите количество или укажите на слайдере:',
      post_quantity_input: 'Введите количество',

      //smartraffic 
      smart_string: 'Такую часть вашего ника нужно ввести в поиск, чтобы вы оказались на 1-ом месте',
      bots: 'ботов',
      bots_0: 'бот',
      bots_1: 'бота',
      days: 'Дней',
      views: 'Просмоторы',
      bots_quantity: 'Введите необходимое количество ботов или укажите на слайдере:',
      bots_quantity_input: 'Введите необходимое количество ботов',
      smarttraffic_alert: 'Чтобы работать с SmarTraffic вам нужно привязать аккаунт',
      add_social_network: 'Добавить соц. сеть',

      // сoverage
      coverage_telegram: 'Охваты в Telegram',
      account_name: 'Имя аккаунта',
      account_bio: 'Bio аккаунта',
      save_data: 'Сохранить данные',
      coverage_on: 'Охваты включены',
      coverage_off: 'Охваты выключены',
      on: 'вкл',
      off: 'выкл',
      
      //leadgene 
      russia: 'Россия',
      europe: 'Европа',
      kazakhstan: 'Казахстан',
      usa: 'USA',
      south_usa: 'Юж USA',
      doesnt_matter: 'Не важно',
      product: 'Продукт',
      product_placeholder: 'Описание продукта компании, для которой мы ищем людей для применения решений. ',
      value_product: 'Ценность продукта',
      value_product_placeholder: 'Ценность продукта, который решает конкретную бизнес-проблему.',
      icp_profile: 'ICP Profile',
      icp_profile_example: 'Профиль людей, которые могут принять решение о покупке продукта компании.',
      example: 'Пример',
      leads_quantity: 'Введите количество лидов',
      email_required: 'Почты обязательны?',
      yes: 'Да',
      no: 'Нет',
      download: 'Скачать',
      usual: 'Обычный',

      // balance 
      balance_top: 'Пополнение баланса',
      sum: 'Сумма',
      tkn: 'TKN',
      bonus: 'Бонус',
      enter_email: 'Введите email',
      go_to_payment: 'Перейти к оплате',
      use: 'Использовать',
      money: 'руб',
      run: 'Запуск',

      //settings
      settings: 'Настройки',
      about_company: 'О вашей компании:',
      about_company_input: 'Описание вашей компании',
      coverage_target_audience: 'Страница с ЦА',
      account_link: 'Привязать аккаунт',
      delete_account: 'Удалить аккаунт',
      delete_company: 'Удалить компанию',
      inctruction_vk: 'Перейдите с помощью кнопки в меню и выберите "Kate Mobile", после чего скопируйте ссылку, куда вам проведут редирект и сохраните в поле для текста ниже',
      delete_sure: 'Вы уверены что хотите удалить Ваш аккаунт?',
      delete_sure_button: 'Да, удалить мой аккаунт'  ,
      exit: 'Выйти из аккаунта',
    
    //auth
    login: 'Вход',
    register: 'Регистрация',
    password: 'Пароль',
    email: 'Email',
    sign_with: 'Зарегестрироваться с',
    verification_code: 'Код подтверждения',
    account_empty: 'У меня нет аккаунта',
    account: 'У меня есть аккаунт',
    register_button: 'Зарегестрироваться',
    incorrect: 'Неверный логин или пароль',
    smth_went_wrong: 'Что-то пошло не так', 
    password_short: 'Пароль слишком короткий',
    match_passwords: 'Пароли не совпадают',
    in_development: 'В разработке',


    add_bots: 'Накрутить ботов',
    total: 'Итог',
    boost: 'Накрутить',
    save: 'Сохранить',
    top_up_balance: 'Пополнить баланс',
    acc_empty: 'Чтобы сделать свой первый пост, добавьте социальную сеть',
    acc_already: 'У вас уже есть аккаунт данной социальной сети',

    transaction_automatic: 'Автоматическое спиcание по операции',
    transaction_history: 'История транзакций',

    posting_transaction: 'Списание по операции Постинг',
    boosting_trancation: 'накрутка',
    smartraffic_transaction: 'SmarTraffic',
    coverage_transaction: 'охваты',
    balance_transaction: 'Пополнение баланса',

    sort: 'Сортировка',
    sort_date: 'Сортировка по дате',
    sort_amount: 'Сортировка по сумме',
    link_account: 'Привязать аккаунт',
    

    smartraffic_alert: 'Чтобы работать с SmarTraffic вам нужно привязать аккаунт Telegram',
    language: 'Язык',
    add_balance: 'Пополните баланс!',
    forget_pass: 'Забыли пароль?',
    login_with: 'Войти с',

    posting_text: 'Колчество постов, которые были опубликованы с помощью платформы',
    go_to_posting: 'Перейти к постингу',
    coverage_text: 'Колчиство людей, которым был показан ваш аккаунт',
    go_to_coverage: 'Перейти к охватам',
    boosting_text: 'Прирост вашей аудитории',
    go_to_boosting: 'Перейти к накрутке',
    leadgen_text: 'Количество найденых лидов',
    go_to_leadgen: 'Перейти к лидгену',

    coverage_how_work_text: 'Придумайте имя, напишите био и добавьте аватарку пользователя.  Это пользователь – бот, который будет просматривать истории, повышая ваши охваты',
    coverage_how_work: 'Как это работает?',

    add_vk_text: `Перейдите с помощью кнопки в меню и выберите "Kate Mobile", после чего скопируйте ссылку, куда вам проведут редирект и сохраните в поле для текста ниже`,
    scan_QR: 'Или отсканируйте QR-код',

    start_lead_generation: 'Чтобы лидген начал работу укажите описание компании:',
    coverage_quantity:'Количество охватов',
    stop: 'Стоп',
    create_coverage: 'Cоздать охваты',
    status: 'Статус'





    },
    [LOCALES.GEORGIAN]: {

      algorithm: 'ალგორითმი თქვენი გვერდის განზრახ ნახვის თქვენი მიზნობრივი აუდიტორიისთვის',
      coverage_quantity: 'გავრცელების რაოდენობა',
      page_with_CA: 'გვერდი მიზნობრივი აუდიტორიით',
      stop: 'შეჩერება',
      create_coverage: 'შექმენით მიღწევები',
      status: 'სტატუსი',
  

      // main page  
      posting: 'პოსტინგი',
      leadgene: 'Leadgene',
      dynamics_of_views: 'ნახვების დინამიკა',
      coverage: 'გავრცელება',
      boosting: 'ბუსტინგი',
      home_page: 'მთავარი',
      balance: 'ბალანსი',

      // company add 
      company_add: 'კომპანიის დამატება',
      company_name: 'შეიყვანეთ კომპანიის სახელი...',

      // posting
      theme_of_post: 'პოსტის თემის დაწერა AI-ის გამოყენებისთვის',
      publishing_post: 'პოსტის გამოქვეყნება',
      delayed_post_button: 'დაგვიანებული პოსტების ნახვა',
      double_click: 'ტექსტის შესაცვლელად ორჯერ დააწკაპუნეთ...',
      generation: 'AI',
      select_date: 'აირჩიეთ თარიღი',

      // delayed posts
      delayed_post: 'დაგვიანებული პოსტები',
      select_social_network: 'აირჩიეთ სოციალური ქსელი',
      post: 'გამოქვეყნება',
      delete: 'წაშლა',
      empty_post: 'თქვენ არ გაქვთ დაგვიანებული პოსტები',

      // boosting
      subscribers: 'გამომწერები',
      likes: 'ლაიქები',
      view: 'ნახვები',
      comments: 'კომენტარები',
      post_link: 'შეიყვანეთ პოსტის ლინკი...',
      post_quantity: 'შეიყვანეთ რაოდენობა ან მიუთითეთ სლაიდერზე:',
      post_quantity_input: 'შეიყვანეთ რაოდენობა',

      // smartraffic 
      smart_string: 'ეს სტრიქონი ძიებაში თქვენ პირველ ადგილზე დაგაყენებთ',
      bots: 'ბოტები',
      bots_0: 'ბოტი',
      bots_1: 'ბოტი',
      days: 'დღეები',
      views: 'ნახვები',
      bots_quantity: 'შეიყვანეთ საჭირო რაოდენობის ბოტები ან მიუთითეთ სლაიდერზე:',
      bots_quantity_input: 'შეიყვანეთ საჭირო რაოდენობის ბოტები',
      smarttraffic_alert: 'SmartTraffic-ის გამოყენებისთვის საჭიროა თქვენი ანგარიშის მიბმა',
      add_social_network: 'სოციალური ქსელის დამატება',

      // coverage
      coverage_telegram: 'გავრცელება Telegram-ში',
      account_name: 'ანგარიშის სახელი',
      account_bio: 'ანგარიშის ბიო',
      save_data: 'მონაცემების შენახვა',
      coverage_on: 'გავრცელება ჩართულია',
      coverage_off: 'გავრცელება გამორთულია',
      on: 'ჩ',
      off: 'გ',
      exit: 'გამოსვლა',

      // leadgen 
      russia: 'რუსეთი',
      europe: 'ევროპა',
      kazakhstan: 'ყაზახეთი',
      usa: 'USA',
      south_usa: 'სამხრეთ USA',
      doesnt_matter: 'მნიშვნელობა არ აქვს',
      product: 'პროდუქტი',
      product_placeholder: 'კომპანიის პროდუქტის აღწერა, რომელზეც ადამიანებს ვეძებთ.',
      value_product: 'პროდუქტის ღირებულება',
      value_product_placeholder: 'პროდუქტის ღირებულება, რომელიც კონკრეტულ ბიზნეს პრობლემას აგვარებს.',
      icp_profile: 'ICP პროფილი',
      icp_profile_example: 'ადამიანების პროფილი, რომლებიც შეიძლება მიიღონ კომპანიის პროდუქტის შეძენის გადაწყვეტილება.',
      example: 'მაგალითი',
      leads_quantity: 'შეიყვანეთ ლიდების რაოდენობა',
      email_required: 'საჭიროა ელ.ფოსტა?',
      yes: 'დიახ',
      no: 'არა',
      download: 'გადმოწერა',
      usual: 'ჩვეულებრივი',

      // balance 
      balance_top: 'ბალანსის შევსება',
      sum: 'თანხა',
      tkn: 'TKN',
      bonus: 'ბონუსი',
      enter_email: 'შეიყვანეთ ელ.ფოსტა',
      go_to_payment: 'გადასვლა გადახდაზე',
      use: 'გამოყენება',
      money: 'usd',

      // settings
      settings: 'პარამეტრები',
      about_company: 'თქვენი კომპანიის შესახებ:',
      about_company_input: 'თქვენი კომპანიის აღწერა',
      coverage_target_audience: 'მიზნობრივი აუდიტორიის გვერდი',
      account_link: 'ანგარიშის მიბმა',
      delete_account: 'ანგარიშის წაშლა',
      delete_company: 'კომპანიის წაშლა',
      inctruction_vk: 'გამოიყენეთ ღილაკი მენიუში და აირჩიეთ "Kate Mobile", შემდეგ კი დააკოპირეთ ბმული, რომელზეც გადაგამისამართებენ და შეინახეთ ტექსტურ ველში ქვემოთ',
      delete_sure: 'დარწმუნებული ხართ, რომ გსურთ თქვენი ანგარიშის წაშლა?',
      delete_sure_button: 'დიახ, წაშალეთ ჩემი ანგარიში',
      save: 'შენახვა',

      total: 'ჯამი',
      balance: 'ბალანსი',
      add_bots: 'ბოტების დამატება',
      boost: 'ბუსტინგი',
      top_up_balance: 'ბალანსის შევსება',
      run: 'გაშვება',
      in_development: 'განვითარების პროცესში',

      // auth
      login: 'შესვლა',
      register: 'რეგისტრაცია',
      password: 'პაროლი',
      email: 'ელ.ფოსტა',
      sign_with: 'შესვლა',
      verification_code: 'ვერიფიკაციის კოდი',
      account_empty: 'მე არ მაქვს ანგარიში',
      account: 'მე მაქვს ანგარიში',
      register_button: 'რეგისტრაცია',
      incorrect: 'არასწორი ლოგინი ან პაროლი',
      smth_went_wrong: 'რაღაც არასწორია...',
      password_short: 'პაროლი ძალიან მოკლეა',
      match_passwords: 'პაროლები არ ემთხვევა',
      acc_empty: 'პირველი პოსტის გასაკეთებლად დაამატეთ სოციალური ქსელი',
      acc_already: 'თქვენ უკვე გაქვთ ამ სოციალური ქსელის ანგარიში',

      transaction_automatic: 'ოპერაციის ავტომატური ჩამოჭრა',
      transaction_history: 'ტრანზაქციების ისტორია',

      posting_transaction: 'პოსტინგის ოპერაციის ჩამოჭრა',
      boosting_trancation: 'ბუსტინგი',
      smartraffic_transaction: 'SmarTraffic',
      coverage_transaction: 'გავრცელება',
      balance_transaction: 'ბალანსის შევსება',

      sort: 'დალაგება',
      sort_date: 'დალაგება თარიღით',
      sort_amount: 'დალაგება რაოდენობით',
      link_account: 'ანგარიშის მიბმა',
      smartraffic_alert: 'SmartTraffic-ის გამოყენებისთვის საჭიროა თქვენი ანგარიშის მიბმა',
      language: 'ენა',
      forget_pass: 'პაროლი დაგავიწყდათ?',
      login_with: 'შესვლა',

      posting_text: 'პოსტების რაოდენობა, რომლებიც გამოქვეყნდა პლატფორმის გამოყენებით',
go_to_posting: 'გადადით პოსტინგზე',
coverage_text: 'ადამიანების რაოდენობა, რომელთაც ნახეს თქვენი ანგარიში',
go_to_coverage: 'გადადით გაშუქებაზე',
boosting_text: 'თქვენი აუდიტორიის ზრდა',
go_to_boosting: 'გადადით ბუსტინგზე',
leadgen_text: 'ნაპოვნი ლიდების რაოდენობა',
go_to_leadgen: 'გადადით ლიდგენზე',

coverage_how_work_text: 'გააფორმეთ სახელი, დაწერეთ ბიო და დაამატეთ მომხმარებლის ავატარი. ეს მომხმარებელი არის ბოტი, რომელიც ნახავს ისტორიებს და გაზრდის თქვენს გაშუქებას',
coverage_how_work: 'როგორ მუშაობს ეს?',

add_vk_text: 'მენიუში ღილაკის დახმარებით გადადით "Kate Mobile"-ზე, შემდეგ კი დააკოპირეთ ბმული, სადაც გადამისამართდებით და შეინახეთ ტექსტის ველში ქვემოთ',
scan_QR: 'ან დაასკანერეთ QR-კოდი',
start_lead_generation: 'ლიდგენის დასაწყებად მიუთითეთ კომპანიის აღწერა:'



  }}