import SNIcon from './SNIcon';
import './SNIcon.css';
import config from '../../constants/config.json'
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';



export default function SnIconOnOff({social_network, is_green = false, canTurnOn=false, value=1, onSwitch=()=>{}}) {
    const [waitingForSwitch, setWaitingForSwitch] = useState(false)
    async function buttonClicked(){
        setWaitingForSwitch(true)
        await onSwitch(social_network)
        setWaitingForSwitch(false)
    }
    return (
        <div className='sn_icon_on_off'>
            <div className={'sn_icon_on_off_wrap ' + (is_green ? 'green' : '')}>
                <SNIcon social_network={social_network} width="40" height="40"/>
                <div className={'sn_icon_inf ' + (is_green ? 'green' : '')}>
                    {value}
                </div>
            </div>
            < div className='buttons_on_off'>
                <button disabled={!canTurnOn || waitingForSwitch}
                 onClick={buttonClicked}
                 className={'button_off ' +(!is_green ? 'active' : '')}><FormattedMessage id='off' /></button>
                <button disabled={!canTurnOn || waitingForSwitch} 
                onClick={buttonClicked}
                className={'button_on '+(is_green ? 'active' : '')}><FormattedMessage id='on' /></button>
            </div>
        </div>
    );
}