import { Link, useParams } from 'react-router-dom';
import Modal from '../Modal/Modal';
import { useBalanceContext } from '../../contexts/BalanceContextProvider';
import React from 'react';
import SubmitButton from '../SubmitButton/SubmitButton';

export default function BalancePopupContent({}) {
    const {setNoBalancePopupOpen} = useBalanceContext()
    return (
        <div className='balance_popup_content_wrap'>
            <div className='balance_popup_text'>
                Вам не хватает баланса для выполнения этого действия!
            </div>
            <Link onClick={()=>setNoBalancePopupOpen(false)} className='default_link' to='/AddBalance'>
                <SubmitButton wide text={'Пополнить баланс'}/>
            </Link>
        </div>
    );
}
