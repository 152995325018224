import {sendRequest, sendFormRequest} from '../utils/requests';


export async function getCompaniesKeyWords() {
    return await sendRequest(`/db/secretgetkeywords`, 'POST', {});
}

export async function changeKeywords(companyId, data) {
    data = {...data, company_id : companyId}
    return await sendRequest(`/db/changekeywords`, 'POST', data);
}