import TelegramIcon from '../../../assets/icons/panel_home'
import styles from './LeadgenCard.module.css'
import { useParams } from 'react-router-dom'
import config from '../../../constants/config.json'
import { useBalanceContext } from '../../../contexts/BalanceContextProvider'
import LeadgenDownloadIcon from '../../../assets/icons/leadgen_download'
import { getPrettyNumber } from '../../../utils/remakeCount'
import SNIcon from '../../SNIcon/SNIcon'
import { FormattedMessage } from 'react-intl'

export const LeadgenCard = ({social_network, is_green, value, unread}) => {
    const {companyId} = useParams()
    const getDownloadLink = ()=>{
        return `${config["api_url"]}/v1/db/getLeads/${companyId}/${social_network}/${localStorage.getItem('auth_token')}`
    }
    const {balance, setNoBalancePopupOpen, prices} = useBalanceContext()
    function goDownloadLeads(){
        console.log(prices)
        console.log(balance)
        console.log(unread)
        if(prices['lead'] && balance < unread*prices['lead']){
            setNoBalancePopupOpen(true)
            return
        }
        window.open(getDownloadLink(), '_blank')
    }

    return (
        <div className={styles.card}>
                <div className={is_green ? styles.cardWrapOn : styles.cardWrapOff}>
                        <SNIcon social_network={social_network} width="60" height="60"/>
                        
                        <p className={styles.count}>{getPrettyNumber(+value) }</p>
                </div>
                <button onClick={goDownloadLeads} disabled={!is_green} className={styles.button}>
                    <FormattedMessage id='download' />
                </button>
                
                {
                    !is_green && <div className={styles.ifOff} />
                }
                
        </div>

    )
}