import React, { useEffect, useState, } from "react"
import { Col, Row, Form, Input} from 'antd';
import "./ShortContent.css"
import { FileUploader } from "../UploadVideo/UploadVideo";
import { postShortVideo } from "../../api/postShortVideo";

const { TextArea } = Input;


async function uploadVideo(image) {
    const formData = new FormData();
    formData.append('inputFile', image);
    const result = await postShortVideo(formData);
    return await result.responce;
}


export const ShortContent = () => {

    const [image, setImage] = useState();

    async function goVideo(){uploadVideo(image)}


    return(
        <>
            <div className="container">
                <div className="short_wrap">
                        <div className="add_sn_header">
                            short content
                        </div>
                        <div className="form">
                            <div className="in_form">
                                <FileUploader 
                                    image = {image}
                                    setImage = {setImage}
                                />
                                <div className="input_area">
                                    <TextArea style = {{height:"100%",borderRadius: "24px",fontSize:"16px"}}
                                        placeholder="Введите описание для видео..."
                                    ></TextArea>
                                    <button className="btn_go" onClick = {goVideo} ><div className="inf_cont">Отправить</div></button>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </>
    )
}