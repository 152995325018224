import { Dropdown } from "antd";
import { Select } from "@mui/material";
import {MenuItem }from "@mui/material";
import { useEffect, useState } from "react";
import {FormControl} from "@mui/material";
import {InputLabel} from "@mui/material";
import styles from './TransactionPage.module.css'
import { DownOutlined } from '@ant-design/icons';
import { sendGetRequest, sendRequest } from "../../utils/requests";
import { FormattedMessage, useIntl } from "react-intl";
import ClipLoader from "react-spinners/ClipLoader";





export function TransactionPage() {
  const intl = useIntl()
    const [age, setAge] = useState('');

    const [isActive, setIsActive] = useState(false);
    const [selected, setIsSelected] = useState(intl.formatMessage({id: 'sort'}));
    const [transactions, setTransactions] = useState()
    

    

    useEffect(() => {
        const getData = async () => {
            const dataFrom =  await sendRequest("/db/gettransactions", 'POST', {})
            const data = dataFrom.responce.transactions
            console.log(data)
            setTransactions(data)


        }

        getData()
    },[])  

    const titles = {
      'posting': intl.formatMessage({id: 'posting_transaction'}),
      'boosting': intl.formatMessage({id: 'boosting_transaction'}),
      'smartraffic': 'SmarTraffic',
      'coverage': intl.formatMessage({id: 'coverage_transaction'}),
      'balance': intl.formatMessage({id: 'balance_transaction'})
    }

    function handleTransactionAutomatic(automatic) {
      console.log(automatic)
        if (automatic == false) {
          return ''
        } else return intl.formatMessage({id: 'transaction_automatic'})
    }

    function handleTransactionTitle(title) {
      return titles[title]
    }

  function handleBlur(e) {
    console.log(e);
  }

    const handleChange =(event) => {
      setAge(event.target.value);
    };


    useEffect(() => {
      if (selected == 'По сумме' || selected == 'Sorting by amount') {
        setTransactions(() => transactions.sort((a, b) => {
          a = Math.min(...Object.values(a).map(v => Number(a.summ)));
          b = Math.min(...Object.values(b).map(v => Number(b.summ)));
          return a - b;
        })
      )
      }

      console.log('sort', transactions)
    }, [selected])


  

    return(
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.header}>
                    <h1><FormattedMessage id='transaction_history' /></h1>

                    <div className={styles.dropdown}>
        <div
          onClick={(e) => {
            setIsActive(!isActive);
          }}
          className={styles.dropdownBtn}
        >
          {selected}
          <span><DownOutlined/></span>
        </div>
        <div
          className={styles.dropdownContent}
          style={{ display: isActive ? "block" : "none" }}
        >
          <div
            onClick={(e) => {
              setIsSelected(e.target.textContent);
              setIsActive(!isActive);
            }}
            className={styles.item}
          >
            {intl.formatMessage({id: 'sort_date'})}
          </div>
          <div
            className={styles.item}
            onClick={(e) => {
              setIsSelected(e.target.textContent);
              setIsActive(!isActive);
            }}
          >
            {intl.formatMessage({id: 'sort_amount'})}
          </div>
          
        </div>
      </div>    
                </div>

                <div className={styles.content}>
                    <div className={styles.list}>
                        {
                          transactions && transactions.map((el) => (
                            <div className={styles.transaction}>
                              <div>
                                  <p className={styles.transactionTitle}>{handleTransactionAutomatic(el.automatic)} {handleTransactionTitle(el.category)}</p>
                                  <p className={styles.transactionData}>{el.created.substring(0, 11).replaceAll('-', ':')}</p>
                              </div>
                        
                              <p className={ el.summ > 0 ? styles.transactionSum : styles.transactionSumNegative}>{el.summ / 100} TKN</p>
                            </div>
                          ))
                        }
                       
                       {
                        transactions == undefined && 
                        <div style={{width: '100%', justifyContent: 'center', display: 'flex'}}>
                          <ClipLoader color='grey'/>
                        </div>
                       }

                    </div>
                </div>

            </div>
        </div>
    )
}