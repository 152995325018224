import { useState } from 'react';
import CenterWindow from '../CenterWindow/CenterWindow';
import InputText from '../InputText/InputText';
import Modal from '../Modal/Modal';
import ParamsAddCompany from '../ParamsAddCompany/ParamsAddCompany';
import SubmitButton from '../SubmitButton/SubmitButton';
import './FirstCompany.css'
import { useNavigate } from 'react-router-dom';
import { addCompany } from '../../api/companies';
import { notification } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

export default function FirstCompany() {
    const [companyName, setCompanyName] = useState('')
    const [waitingForAdd, setWaitingForAdd] = useState(false)
    const intl = useIntl()
    const navigate = useNavigate()
    async function paramsSubmit(){
        setWaitingForAdd(true)
        const result = await addCompany({title : companyName})
        if(!result.status){
            notification.error({
                message: intl.formatMessage({id: 'smth_went_wrong'})
            });
            setWaitingForAdd(false)
        }else{
            navigate(`/main/${result.responce.new_company.company_id}`)
        }
    }
    return (
        <CenterWindow maxWidth={800}>
            <div className='first_company_content'>
                <div className='window_header'>
                   <FormattedMessage id='company_add' />
                </div>
                <InputText changeHandlerValue={setCompanyName}
                placeholer={intl.formatMessage({id: 'company_name'})} wide/>
                <SubmitButton text={'Перейти к работе'} customClassName={'gowork_button'}
                clickHandler={paramsSubmit} disabled={waitingForAdd}/>
            </div>
        </CenterWindow>

    );
}
