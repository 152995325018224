import { useIntl } from 'react-intl';
import FacebookIcon from '../../assets/icons/fb';
import TelegramIcon from '../../assets/icons/tg';
import VKIcon from '../../assets/icons/vk';
import styles from './StatusForCoverage.module.css'
import { Progress, notification } from 'antd';

export function StatusForCoverage() {
    const intl = useIntl()

    return (
        <div className={styles.container}>
            <p className={styles.title}>{intl.formatMessage({id: 'status'})}</p>
            <div className={styles.content}>
            <Progress size={200} type='circle' format={(percent) => '0/300'}/>
                <div className={styles.infoContainer}>
                    <div className={styles.progressElement}>
                        <TelegramIcon width='35' height='35'/>
                        <Progress format={(percent) => '0/100'}/>
                        <button className={styles.button}>{intl.formatMessage({id: 'stop'})}</button>
                    </div>
                    <div className={styles.progressElement}>
                        <VKIcon width='35' height='35'/>
                        <Progress format={(percent) => '0/100'}/>
                        <button className={styles.button}>{intl.formatMessage({id: 'stop'})}</button>
                    </div>
                    <div className={styles.progressElement}>
                        <FacebookIcon width='35' height='35'/>
                        <Progress format={(percent) => '0/100'}/>
                        <button className={styles.button}>{intl.formatMessage({id: 'stop'})}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}