export const TelegramIcon = ({onClick, color}) => (
    
    <svg onClick={onClick}  width="60" height="60" viewBox="0 0 77 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4741_455)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M76.1551 38.1674C76.1551 59.049 59.2272 75.9769 38.3456 75.9769C17.4641 75.9769 0.536133 59.049 0.536133 38.1674C0.536133 17.2859 17.4641 0.35791 38.3456 0.35791C59.2272 0.35791 76.1551 17.2859 76.1551 38.1674ZM25.8551 34.7039C22.0675 36.3576 18.176 38.0568 14.6174 40.0169C12.7593 41.3774 15.2289 42.3398 17.5458 43.2425C17.9142 43.3861 18.2786 43.528 18.6212 43.6698C18.9063 43.7575 19.1962 43.8499 19.49 43.9434C22.0669 44.7644 24.9401 45.6798 27.4418 44.3028C31.5514 41.9421 35.4296 39.2128 39.3049 36.4857C40.5745 35.5921 41.8438 34.6989 43.1208 33.8188C43.1807 33.7806 43.2481 33.7368 43.322 33.689C44.4098 32.9837 46.8565 31.3978 45.9515 33.5832C43.8115 35.9236 41.5191 37.9952 39.2145 40.0784C37.6611 41.4822 36.1021 42.8912 34.5803 44.391C33.2549 45.4679 31.8785 47.6336 33.3627 49.1418C36.7811 51.5348 40.253 53.8699 43.7228 56.2036C44.8519 56.963 45.9809 57.7223 47.1077 58.4836C49.0174 60.0082 52.0019 58.7748 52.4218 56.3924C52.6085 55.2964 52.7959 54.2005 52.9834 53.1045C54.0194 47.0476 55.0557 40.9885 55.9712 34.9119C56.0956 33.9586 56.2366 33.0055 56.3778 32.0519C56.7198 29.7406 57.0623 27.4265 57.1692 25.1028C56.8936 22.7838 54.0818 23.2938 52.5171 23.8152C44.4744 26.8756 36.512 30.1628 28.5812 33.508C27.6827 33.906 26.772 34.3036 25.8551 34.7039Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_4741_455">
    <rect width="75.619" height="75.619" fill="white" transform="translate(0.536133 0.35791)"/>
    </clipPath>
    </defs>
    </svg>
    
    )