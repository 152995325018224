import './ParamsAddCompany.css';
import InputText from '../InputText/InputText'
import SubmitButton from '../SubmitButton/SubmitButton';
import { useRef } from 'react';
import { addCompany } from '../../api/companies';
import { notification } from 'antd';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

export default function ParamsAddCompany({closeModal}) {
    const params = useRef({})
    const intl = useIntl()
    function changeHandler(name, new_value){
        params.current[name] = new_value
    }
    async function paramsSubmit(){
        const result = await addCompany(params.current)
        if(!result.status){
            notification.error({
                message: 'Произошла ошибка'
            });
        }else{
            await closeModal({id : result.responce.new_company.company_id, company : result.responce.new_company})
        }
    }
    return (
        <div className='params_wrap'>
            <div className='modal_name'>
                <FormattedMessage id='company_add' />
            </div>
            <div className='add_comp_params_wrap'>
                <div className='param_wrap'>
                    <InputText placeholer={intl.formatMessage({id: 'company_name'})}
                     changeHandler={changeHandler} name={'title'}/>
                </div>
                <SubmitButton text={intl.formatMessage({id: 'save'})} wide clickHandler={paramsSubmit}/>
            </div>
        </div>
    );
}
