import { Link, useParams } from 'react-router-dom';
import './AddSNLeadgenBlock.css';
import SNIconBlockInf from '../../SNIcon/SNIconBlockInf';
import SNIconInf from '../../SNIcon/SNIconWithInf';
import InfoButton from '../../InfoButton/InfoButton';
import InfoPopup from '../../InfoPopup/InfoPopup';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

var needSN = ['vk', 'inst', 'tg', 'tw']

export default function AddStoriesBlock({boostingData}) {
    const { companyId } = useParams()
  const [infoPopupOpen, setInfoPopupOpen] = useState(false)
    const intl = useIntl()
    return (
        <div className='block_wrap'>
            <div className='block_name'>
                <FormattedMessage id='boosting' />
                <InfoButton onInfoPopupOpen={setInfoPopupOpen}/>
                <InfoPopup infoPopupOpen={infoPopupOpen} onInfoPopupOpen={setInfoPopupOpen}  buttonLink={'boosting'} buttonText={intl.formatMessage({id: 'go_to_boosting'})}  title={intl.formatMessage({id: 'boosting'})}><FormattedMessage id={'boosting_text'} /></InfoPopup>
            </div>
            < div className='block_cards_boosting'>
                {needSN.map((sn)=>(
                            <SNIconInf social_network={sn} value={boostingData[sn] || 0}/>
                        ))}
                
            </div>
        </div>
    )
}
    