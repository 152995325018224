import React, { createRef, useEffect, useState } from "react";
import { LeftPanel } from "./LeftPanel";

const MIN_WIDTH = 60;
const MAX_WIDTH = 230;

const LeftPane = ({ leftWidth, setLeftWidth, bgColor }) => {
  const leftRef = createRef();

  useEffect(() => {
    if (leftRef.current) {
      if (!leftWidth) {
        setLeftWidth(MAX_WIDTH);
        return;
      }

      leftRef.current.style.width = `${leftWidth}px`;
    }
  }, [leftRef, leftWidth, setLeftWidth]);


  return <div className="left_panel_wrap" style={{backgroundColor : bgColor}}
   ref={leftRef}><LeftPanel leftWidth={leftWidth} MIN_WIDTH={MIN_WIDTH} MAX_WIDTH={MAX_WIDTH}/></div>;
};

export const SplitView = ({
  right,
  className,
  color
}) => {
  const [leftWidth, setLeftWidth] = useState(MAX_WIDTH);
  const [separatorXPosition, setSeparatorXPosition] = useState(undefined);
  const [dragging, setDragging] = useState(false);

  const splitPaneRef = createRef();
  const physLeftRef = createRef();

  useEffect(()=>{
    
    if(physLeftRef.current){
      physLeftRef.current.style.width = `${leftWidth}px`
    }
  }, [leftWidth, physLeftRef])

  const onMouseDown = (e) => {
    setSeparatorXPosition(e.clientX);
    setDragging(true);
  };

  const onTouchStart = (e) => {
    setSeparatorXPosition(e.touches[0].clientX);
    setDragging(true);
  };

  const onMove = (clientX) => {
    if (dragging && leftWidth && separatorXPosition) {
      const newLeftWidth = leftWidth + clientX - separatorXPosition;
      setSeparatorXPosition(clientX);
      if (clientX < MIN_WIDTH + (MAX_WIDTH - MIN_WIDTH)/4) {
        setLeftWidth(MIN_WIDTH);
        return;
      }else if(clientX > MIN_WIDTH + 10){
        setLeftWidth(MAX_WIDTH);
        return;
      }
      return

      // if (splitPaneRef.current) {
      //   const splitPaneWidth = splitPaneRef.current.clientWidth;

      //   if (newLeftWidth > splitPaneWidth - MIN_WIDTH) {
      //     setLeftWidth(splitPaneWidth - MIN_WIDTH);
      //     return;
      //   }
      // }

      // setLeftWidth(newLeftWidth);
    }
  };

  const onMouseMove = (e) => {
    if (dragging){
    e.preventDefault();
    onMove(e.clientX);
    }
  };

  const onTouchMove = (e) => {
    onMove(e.touches[0].clientX);
  };

  const onMouseUp = () => {
    setDragging(false);
  };

  React.useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  });

  return (
    
    <div className={`splitView ${className ?? ""}`} ref={splitPaneRef}>
      <div className="phys_left" ref={physLeftRef}></div>
      <div className="left_all_wrap">
        <LeftPane leftWidth={leftWidth} setLeftWidth={setLeftWidth}
        bgColor={color}/>
        <div
          className="divider-hitbox"
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          onTouchEnd={onMouseUp}
        >
          <div className="divider" />
        </div>
      </div>
      <div className="rightPane">{right}</div>
    </div>
  );
};
