import React, { useEffect, useRef, useState } from 'react';
import './AddBalancePage.css'
import backArrow from '../../assets/icons/backarrow.svg'
import BonusVariant from './BonusVariant';
import SubmitButton from '../SubmitButton/SubmitButton';
import { useBalanceContext } from '../../contexts/BalanceContextProvider';
import InputText from '../InputText/InputText';
import { getPaymentUrl } from '../../api/addBalance';
import { notification } from 'antd';
import UserSmallIcon from '../../assets/icons/usericon';
import { useParams } from 'react-router-dom';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {useIntl} from 'react-intl';
import ClipLoader from 'react-spinners/ClipLoader';

import { useNavigate } from 'react-router-dom';

export default function AddBalancePage({}) {
    const [sum, setSum] = useState(0)
    const [bonus, setBonus] = useState(0)
    const [tokens, setTokens] = useState(0)
    const [email, setEmail] = useState('')
    const [sumInputWidth, setSumInputWidth] = useState(0)
    const { bonuses } = useBalanceContext()
    const {companyId} = useParams()
    const rubsSpan = useRef()
    const intl = useIntl()
    const navigate = useNavigate()

    useEffect(()=> {

            setSumInputWidth( rubsSpan.current ? rubsSpan.current.offsetWidth + 20 : 40)
        
        
    }, [sum])
    useEffect(()=>{
        if((!bonuses) || (bonuses.length==0)){
            setTokens('--')
            setBonus('--')
            return
        }
        for(let cur_bonus of bonuses){
            console.log(cur_bonus['sum'])
            if(sum >= cur_bonus['sum']){
                setBonus(cur_bonus['bonus'])
                setTokens(Math.round(((sum*(1 + cur_bonus['bonus']/100))/100) * 100) / 100)
                return
            }
        }
        setBonus(0)
        setTokens(Math.round(((sum)/100) * 100) / 100)
    }, [sum, bonuses])
    function variantSelected(newSum, newBonus){
        setSum(newSum)
        setBonus(newBonus)
    }
    
    const [waitingForPaymentUrl, setWaitingForPaymentUrl] = useState(false)
    async function addBalanceSubmitted(){
        setWaitingForPaymentUrl(true)
        const result = await getPaymentUrl({
            'summ' : sum,
            'email' : email ? email : 'info@generext.com',
            'company_id' : companyId
        })
        if(result.status){
            window.open(result.responce.url, "_self")
        }else{
            notification.error({message: intl.formatMessage({id: 'add_balance'})})
        }
        setWaitingForPaymentUrl(false)
    }
    const remove_leading_zeros = (num) => {
        if (isNaN(parseInt(num))){
            return '0'
        }
        return '' + parseInt(num)
    }

    console.log(bonuses)

    if (!bonuses.length) {
        return (
        <div style={{width: '100%', height: '65vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ClipLoader color='grey'/>
        </div>)
    }

    

    return (
        <React.Fragment>
            <span ref={rubsSpan} className='sum_hidden_span'>{sum}</span>
            <div className='container-fluid'>
                <div className='row g-3' style={{alignItems: 'stretch'}}>
                    <div className='col-12 col-lg-8 vertical_block'>
                        <div className='add_balance_poster'>
                            <FormattedMessage id='balance_top' />
                        </div>
                        <div className='add_balance_block_wrap'>
                            <div className='add_balance_flex_row'>
                                <div className='add_balance_label'>
                                    <span>
                                        <FormattedMessage id='sum' />
                                    </span>
                                </div>
                                <div className='sum_wrap'>
                                    <input
                                    value={sum}
                                     onChange={e=>setSum(remove_leading_zeros(e.target.value))}
                                    type='number'
                                     className='sum_input'
                                     autoFocus
                                     style={{width: sumInputWidth}}/>
                                    <div className='sum_rub_label'>
                                        <span>
                                            <FormattedMessage id='money' />
                                        </span>
                                    </div>
                                </div>
                                <div className='bonus_percent_wrap'>
                                    {bonus}%
                                </div>
                            </div>
                            
                            <div className='add_balance_flex_row'>
                                <div className='add_balance_label'>
                                    <span>
                                        В TKN:   
                                    </span>
                                </div>
                                <div className='sum_wrap'>
                                    <div className='tokens_text'>
                                        {tokens} TKN
                                    </div>
                                </div>
                            </div>
                            <div className='add_balance_submit_block'>
                                <InputText placeholer={intl.formatMessage({id: 'enter_email'})}
                                type='email' changeHandlerValue={setEmail} IconComp={UserSmallIcon}/>
                                <SubmitButton disabled={waitingForPaymentUrl} clickHandler={addBalanceSubmitted} text={intl.formatMessage({id: 'go_to_payment'})}/>
                                <button className='btn-act' onClick={() => navigate(`/transaction/${companyId}`)}><FormattedMessage id='transaction_history' /></button>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-4' style={{flex: '1'}}>
                        <div className='all_bonuses_wrap'>
                            {bonuses.map(cur_bonus=>(
                                <BonusVariant sum={cur_bonus.sum}
                                 bonus={cur_bonus.bonus}
                                 variantSelected={variantSelected}/>
                            ))}
                        </div>       
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
