import {sendRequest, sendFormRequest} from '../utils/requests';

export async function addPost(companyId, data, onBalanceLack) {
    data = {...data, company_id : companyId}
    const curData = {...data, texts : JSON.stringify(data['texts']),
     social_networks : JSON.stringify(data['social_networks'])}
    return await sendFormRequest(`/db/addPost`, 'POST', curData, onBalanceLack);
}

export async function getGptText(data) {
    return await sendRequest(`/db/getgpttext`, 'POST', data);
}
