import './Modal.css';
import React, { useEffect, useState }  from 'react';
import { Icon24DismissDark } from '@vkontakte/icons';

export default function Modal({modalClosed, title, ComponentContent, socialNetwork, SNinfo}) {
    const [animation, setAnimation] = useState("slide-in")
    useEffect(() => {
        setAnimation("slide-in")
    }, [])
    async function closeModal(data = {}){
        setAnimation("slide-out")
        setTimeout(() => modalClosed(data), 350)
    }
    return (
        <div className={"modal " + animation} onClick={closeModal}>
            <div className={"modal-dialog " + animation} onClick={e => {e.stopPropagation();}} >
                <div className="modal-body">
                    <div className="modal_content" style={{"transition" : "all 1s ease-out"}}>
                        <ComponentContent closeModal={closeModal} socialNetwork={socialNetwork} SNinfo={SNinfo}/>
                    </div>
                </div>
            </div>
        </div>
    );
}