import { useState } from "react"
import EditableText from "../NewPostTextEditCard/EditableText"
import SubmitButton from "../SubmitButton/SubmitButton"
import noPic from '../../assets/pics/nopic.png'
import { Button, DatePicker } from "antd"
import ruRU from 'antd/locale/ru_RU'
import styles from './PostponedPostCard.module.css'
import backgroundImage from '../../assets/pics/post_background.png'
import IconAnimated from "../Icon/IconAnimated"
import editIcon from '../../assets/animatedIcons/edit.json'
import SNIconWithoutInf from "../SNIcon/SNIconWithoutInf"
import { sendRequest } from "../../utils/requests"
import { sendFormRequest } from "../../utils/requests"
import { addPost } from "../../api/addPost"
import { useParams } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"


export function PostponedPostCard({post, onHandleDeleted}) {
    const {companyId} = useParams()
    const intl = useIntl()
    const [selectedImage, setSelectedImage] = useState(post['photo'] ? post['photo'] : noPic)
    const [imageToSend, setImageToSend] = useState()
    const [selectedDatetime, setSelectedDatetime] = useState(new Date());
    const [text, setTexts] = useState(post['text'])
    const [dataToSend, setDataToSend] = useState({})

    function urltoFile(url, filename, mimeType){
        if (url.startsWith('data:')) {
            var arr = url.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[arr.length - 1]), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            var file = new File([u8arr], filename, {type:mime || mimeType});
            return Promise.resolve(file);
        }
        return fetch(url)
            .then(res => res.arrayBuffer())
            .then(buf => new File([buf], filename,{type:mimeType}));
    }
    


    function datetimeChanged(newDt){
        setSelectedDatetime(newDt)
        const options = {hour12 : false} 
        setDataToSend({ ...dataToSend, 'post_date':newDt.toLocaleString("en-US", options)})
    }

    async function sendPost(company_id, text, social, photo) {
        const d = Intl.DateTimeFormat("ru", {dateStyle: "short", timeStyle: "short"}).format(new Date()) + ':00'
        const d2 = d.slice(3, 6) + d.slice(0, 2) + d.slice(5, 21)
        const data = {
            'company_id' : company_id,
            'texts' : { 
                [social] : text
            },
            'post_datetime': d2.replaceAll('.', '/'),
            'social_networks' : [social]
        }

        if (photo != noPic) {
            const res = await handlePhotoToSend(photo)
            data['image'] = res
        }

        const res = await addPost(companyId, data)
        console.log(res)

        deletePost(post['id'])

    }

    async function handlePhotoToSend(photo){
            const nowPhoto = `data:image/png;base64, ${photo}`
            const res = await urltoFile(nowPhoto, 'image.png','image/png')
            return res
        
    }

    function getImgSrc(){
        if (selectedImage === noPic){
            return noPic
        } else if (selectedImage === post['photo']) {
            console.log(`data:image/png;base64, + ${selectedImage}`)
            return `data:image/png;base64, ${selectedImage}`
        }
        console.log(post['id'])
        return URL.createObjectURL(selectedImage)
    }

    async function sendData(text, id, photo) {

            if (text === post["text"]) {
                
            } else {
                const res = await sendRequest('/db/changeposttext', 'POST', {"post_id": id, "new_text": text})
                console.log(res)
            }

            if (photo === post["photo"]) {
               
            } else {
                const res = await sendFormRequest('/db/changepostphoto', 'POST', {'image': photo, 'post_id': id})
                console.log(res)
            }
            
    }

    async function deletePost(id) {
        const res = await sendRequest('/db/deletepost', 'POST', {"post_id": id})
        onHandleDeleted(id)
    }


    return (
        <div className={styles.container}>
            <div style={{position: 'relative'}}>

                <img width={330} style={{height: '330px'}} onClick={() => document.getElementById(`input_photo_${post['id']}`).click()} src={getImgSrc()} alt='post_image'/>

                <input style={{display: 'none'}} id={`input_photo_${post['id']}`} type='file' onChange={(event) => {
                        if(event.target.files.length != 0){
                            setSelectedImage(event.target.files[0]);
                        }}}  />
                {
                    selectedImage != noPic && 
                <div onClick={() => setSelectedImage(noPic)} style={{position: 'absolute', top: 10, right: 10 ,background: 'white', width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '30px'}}>&times;</div>        

                }
                <div onClick={() => document.getElementById(`input_photo_${post['id']}`).click()} style={{position: 'absolute', bottom: 10, right: 10 ,background: 'white', width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '30px'}}>
                    <IconAnimated icon={editIcon} />
                </div>
            </div>
            
            <div className={styles.textsContainer}> 
               
                        <div style={{display: 'flex', width: '100%'}}>
                             <SNIconWithoutInf 

                            social_network={post['social_network_title']}
                            height='40' width='40' widthCircle='50'  key={post['social_network_title']}/> 
                            <EditableText value={text} changeHandler={(val) => setTexts(val)} />
                        </div>
    
                
            </div>
            <div className={styles.buttonsContainer}>
                <div className={styles.buttons}>

                        <div className={styles.dateContainer}>
                        <p>{post['date_of_posting']}</p>
                   </div>

                   <button onClick ={() => sendPost(companyId, text, 'tg', selectedImage)} className={styles.button}><FormattedMessage id='post' /></button>

                   

                    
                </div>
                <div style={{ width: '95%', margin: 'auto', display: 'flex', justifyContent: 'space-between', marginBottom: 10,}}>
                    
                    <SubmitButton style={{width: '48%', minHeight: 45}} disabled={post['photo'] === selectedImage && post['text'] === text || (!post['photo'] && selectedImage === noPic )} clickHandler = {() => sendData(text, post["id"], selectedImage)} text={intl.formatMessage({id: 'save'})} />
                        
                    <button onClick={() => deletePost(post["id"])} className={styles.deleteButton}><FormattedMessage id='delete' /></button>
                </div>
            </div>  
        </div>
    )
}