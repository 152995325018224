import React from "react";

export function OK() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <g clipPath="url(#clip0_5523_2)">
        <path
          fill="#11C424"
          d="M15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15z"
        ></path>
        <path
          fill="#fff"
          d="M12.727 22.781l-6.915-6.914a.227.227 0 010-.328l1.993-1.992a.226.226 0 01.328 0l4.758 4.758 8.953-8.953a.227.227 0 01.328 0l1.992 1.992a.227.227 0 010 .328l-11.11 11.11a.226.226 0 01-.327 0z"
        ></path>
        <path
          stroke="red"
          strokeWidth="423"
          d="M-254.844 -425.348H1203.7559999999999V351.22999999999996H-254.844z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5523_2">
          <path fill="#fff" d="M0 0H30V30H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}