import './AddSNLeadgenBlock.css';
import SNIconDownload from '../../SNIcon/SNIconDownload';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';

const data = [{day: 1, amt : 10},{day: 2, amt : 100},{day: 3, amt : 30},{day: 4, amt : 22},{day: 5, amt : 70}, {day: 6, amt : 100},]

export default function AddGrafBlock({graphicData}) {
    return (
      <div className='block_wrap'>
        <div className='block_name'>
          <FormattedMessage id='dynamics_of_views'/>
            
        </div>
        <div className='chart_views'>
          <ResponsiveContainer width={'100%'}>
            <LineChart  data={graphicData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
              <Line type="monotone" dataKey="total" stroke="#FF3D00" strokeWidth={3}/>
              {/* <CartesianGrid stroke="#" strokeDasharray="5 5" /> */}
              <XAxis stroke='#ffffff' dataKey="da y" />
              <YAxis stroke='#ffffff'/>
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
}
