import styles from './LoadingScreen.module.css'
import { useBalanceContext } from '../../contexts/BalanceContextProvider'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import logo from '../../assets/icons/logo.svg'

export const LoadingScreen = () => {

    return (
        <>
        <div className={styles.wrap}>
            <img src={logo} className={styles.logo}/>
        </div>
        </>
    )
}