import {sendRequest, sendFormRequest} from '../utils/requests';

export async function addSocialNetwork(socialNetwork, companyId, data) {
    data = {...data, company_id : companyId}
    return await sendRequest(`/db/add${socialNetwork}`, 'POST', data);
}

export async function getSecretId(companyId, data) {
    data = {...data, company_id : companyId}
    return await sendRequest(`/db/getsecretid`, 'POST', data);
}


export async function getAccounts(companyId, data) {
    data = {...data, company_id : companyId}
    return await sendRequest(`/db/getaccounts`, 'POST', data);
}

export async function getAddSnData(companyId, data) {
    data = {...data, company_id : companyId}
    return await sendRequest(`/db/getaddsndata`, 'POST', data);
}


export async function changeCompanyDesc(companyId, data) {
    data = {...data, company_id : companyId}
    return await sendRequest(`/db/changecompanydescription`, 'POST', data);
}

export async function changeCoveragePage(data) {
    return await sendRequest(`/db/changecoveragepage`, 'POST', data);
}

export async function getCompanyDesc(companyId, data) {
    data = {...data, company_id : companyId}
    return await sendRequest(`/db/getcompanydescription`, 'POST', data);
}
