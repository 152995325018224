import React, { useEffect, useState,useRef } from "react"
import { Col, Row } from 'antd';
import {SyncOutlined} from '@ant-design/icons'
import { FileUpload,Tooltip,Toast } from 'primereact';
import "./UploadVideo.css"

export const FileUploader = ({image,setImage}) => {
  const [imageURL, setImageURL] = useState();
  const fileReader = new FileReader();

  const inputFileRef = useRef();
  
  fileReader.onloadend = () => {
    console.log(fileReader.result)
    setImageURL(fileReader.result);
  };

  const handleOnChange = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files.length) {

      const file = event.target.files[0];
      setImage(file);
      fileReader.readAsDataURL(file);
    }
  };

  const handleDrop = (event) => {
    
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length) {
      setImage(event.dataTransfer.files[0]);
      fileReader.readAsDataURL(event.dataTransfer.files[0]);
    }
  };

  const handleDragEmpty = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  return (
    <div className = "input_block">
      <input
        ref={inputFileRef}
        id="file-loader-button"
        type="file"
        className="file-uploader__upload-button"
        onChange={handleOnChange}
      />
      <button onClick={onBtnClick} className="btn_block">
          {imageURL ?
            <div>
              <button onClick={onBtnClick} className="btn_block"></button>

              <video 
                  src={imageURL}
                  className="file-uploader__preview"
                  alt="preview"
                  onDrop={handleDrop}
                  onDragEnter={handleDragEmpty}
                  onDragOver={handleDragEmpty}
                  
                />
                
            </div>
            :
            <div className="img_none">
              <div className="inf_cont">
                Добавить видео
              </div>
            </div>
          }
      </button>
    </div>
  );
};


