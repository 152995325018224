import './CenterWindow.css';
import SLLogo from '../../assets/icons/logo.png';
import SLLogoWhite from '../../assets/icons/logowhite';


export default function CenterWindow({maxWidth=450, children}) {
  return (
    <div className='window_wrap'>
      <div className='window_logo'>
        <SLLogoWhite width='315' height={undefined}/>
      </div>
      <div className='window'  style={{maxWidth: `${maxWidth}px`}}>
        {children}
      </div>
    </div>
  );
}
