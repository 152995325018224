import styles from './CreateCoverage.module.css'
import TelegramIcon from '../../assets/icons/tg'
import VKIcon from '../../assets/icons/vk'
import FacebookIcon from '../../assets/icons/fb'
import { FormattedMessage, useIntl } from 'react-intl'
import SubmitButton from '../SubmitButton/SubmitButton'
import { useBalanceContext } from '../../contexts/BalanceContextProvider'


export function CreateCoverage() {
    const intl = useIntl()
    const {prices, balance} = useBalanceContext()

    return (
        <div className={styles.container}>
            <p className={styles.title}>{intl.formatMessage({id: 'create_coverage'})}</p>
            <div className={styles.content}>
                <div className={styles.element}>
                    <div className={styles.inputContainer}>
                        <TelegramIcon width='35' height='35'/>
                        <input className='CA_input' placeholder={intl.formatMessage({id: 'coverage_quantity'})}/>
                    </div>
                    <p>120 TKN</p>
                </div>
                <div className={styles.element}>
                    <div className={styles.inputContainer}>
                        <VKIcon width='35' height='35'/>
                        <input className='CA_input' placeholder={intl.formatMessage({id: 'coverage_quantity'})}/>
                    </div>
                    <p>120 TKN</p>
                </div>
                <div className={styles.element}>
                    <div className={styles.inputContainer}>
                        <FacebookIcon width='35' height='35'/>
                        <input className='CA_input' placeholder={intl.formatMessage({id: 'coverage_quantity'})}/>
                    </div>
                    <p>120 TKN</p>
                </div>
            </div>
            <div className='price_block' style={{width: '100%', margin: 'auto', padding: 0, background: 'rgb(175,180,183)'}}>
                <div style={{display: 'flex', width: '30%', alignItems: 'center'}}>
                    <p className='price_text' style={{width: '80%', fontSize: '1.5rem'}}><FormattedMessage id='total' />: {'10'/100} TKN</p>
                    
                    </div>
                    
                    
                    <p className='balance_text' style={{fontSize: '1.2rem'}}><FormattedMessage id='balance' />: {balance/100} TKN</p>
                    <SubmitButton withBorder={true} disabled={balance < 1000}text={intl.formatMessage({id: 'top_up_balance'})}  style={{marginLeft: '10%', background: 'rgb(255,61,0)', color: 'white'}}/>
                </div>
        </div>
    )
}