export function getPrettyNumber(number){

    try{
        if (number >= 1000000){
            number/= 1000000
            return number.toFixed(1) + "m"    
        }
        console.log(number)
        if(number >= 1000){
            number /= 1000
            return number.toFixed(1) + "k";
        }
        return number
    }catch{
        return "err"
    }
}