import React, { useEffect, useState } from 'react';
import './AddBalancePage.css'
import { useNavigate, useParams } from 'react-router-dom';
import { getPaymentConfirmation } from '../../api/addBalance';
import { useBalanceContext } from '../../contexts/BalanceContextProvider';
import ClipLoader from 'react-spinners/ClipLoader';

export default function ConfirmPaymentPage() {
    const {paymentId, companyId} = useParams()
    const {setBalance} = useBalanceContext()
    const [confirmed, setConfirmed] = useState(false)
    const navigator = useNavigate()
    let __useEffectDone = false
    useEffect(() => {
        async function getData(){
            const result = await getPaymentConfirmation({
                'payment_id' : paymentId
            })
            setBalance(result)
            setConfirmed(true)
            setTimeout(()=>navigator('/main'), 2000)
            // await new Promise(resolve=>setTimeout(()=>{
                
            //     resolve()}, 2000))
        }
        if(!paymentId || __useEffectDone)return
        __useEffectDone = true
        getData()
    }, [paymentId])
    return (
        <div className='payment_loading_div'>
            {!confirmed ?
                <>
                    <div className='payment_loading_text'>
                        Ожидайте подтверждения платежа!
                    </div>
                    <ClipLoader color="gray"/>
                </>
            :
                <div className='payment_loading_text'>
                    Платёж подтверждён!
                </div>
            }
        </div>
    );
}
