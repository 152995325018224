import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';
import Login from './components/Login/Login';
import { Navigate, Route, BrowserRouter as Router, Routes, useNavigate, useParams} from 'react-router-dom';
import Register from './components/Register/Register';
import AddSocialNetwork from './components/AddSocialNetwork/AddSocialNetwork';
import MyCompanys from './components/MyCompanys/MyCompanys';
import AddPostMenu from './components/AddPostMenu/AddPostMenu';
import { MainPage } from './components/MainPage/MainPage';
import { ShortContent } from './components/ShortContent/ShortContent';
import { Tariffs } from './components/Tariffs/Tariffs';
import { AddPostContextProvider } from './contexts/AddPostContextProvider';
import BoostingMenu from './components/BoostingMenu/BoostingMenu';
import AuthBase from './components/AuthBase/AuthBase';
import BalancePopupProvider from './components/BalancePopup/BalancePopupProvider';
import AddBalancePage from './components/AddBalancePage/AddBalancePage';
import ConfirmPaymentPage from './components/AddBalancePage/ConfirmPaymentPage';
import FirstCompany from './components/FirstCompany/FirstCompany';
import { CoveragePage } from './components/CoveragePage/CoveragePage';
import { LeadgenPage } from './components/LeadgenPage/LeadgenPage';
import { SmartTrafficPage } from './components/SmartTrafficPage/SmartTrafficPage';
import { PostponedPostPage } from './components/PostponedPostsPage/PostponedPostPage';
import { LoadingScreen } from './components/LoadingScreen/LoadingScreen';
import * as amplitude from '@amplitude/analytics-browser';
import { LOCALES } from './i18n/locales';
import { messages } from './i18n/messages';
import { IntlProvider } from 'react-intl';
import { BalanceContextProvider, useBalanceContext } from './contexts/BalanceContextProvider';
import { TransactionPage } from './components/TransactionPage/TransactionPage';
import { EnterEmailPage } from './components/EnterEmailPage/EnterEmailPage';
import { ForgetPasswordPage } from './components/ForgetPasswordPage/ForgetPasswordPage';
import LeadgenAdminPage from './components/LeadgenAdminPage/LeadgenAdminPage';


function App() {
  const [isRedirecting, setIsRedirecting] = useState(true)
  const [selectedLang, setSelectedLang] = useState(LOCALES.ENGLISH)
  const locale = LOCALES.ENGLISH

  var lang2 = navigator.language || navigator.userLanguage; 

  function handleInitLang() {

    const selLang = localStorage.getItem('lang')

    console.log('APP JS', selLang)
    
  
    if (selLang == 'ru-RU') {
      setSelectedLang(LOCALES.RUSSIAN)
    } else if (selLang == 'en-US') {
      setSelectedLang(LOCALES.ENGLISH)
    } else if (lang2.indexOf('ru') != -1) {
      setSelectedLang(LOCALES.RUSSIAN)
    } else if ( selLang != null && selLang.indexOf('ka') != -1) {
      setSelectedLang(LOCALES.GEORGIAN)
    } else if (lang2.indexOf('ka') != -1) {
      setSelectedLang(LOCALES.GEORGIAN)
    } else (
      setSelectedLang(LOCALES.ENGLISH)
    )
    
  }

  

  useEffect(()=>{
    const eventProperties = {
      browserEngine: 'example'
    };
    async function xd(){
      console.log("SALAMMM");
      let p = window.amplitude.track('UserLandedOnSite', eventProperties).promise
      console.log(p)
      p.then((e => console.log(e)), (e => console.log("xd")));
      console.log("SALAMMM2");
    }
    xd()
    if(window.screen.availWidth < 1000){
      window.location.href = 'https://mobile.socialite.fun'
    }else{
      setIsRedirecting(false)
    }
    handleInitLang()
  }, [])


  return (
    
    <div className="App" >

      <IntlProvider messages={messages[selectedLang]}
      locale={selectedLang}
      defaultLocale={selectedLang}>

      {isRedirecting ? 
      <LoadingScreen/>
      :
      <Router>
        <Routes>
          
          <Route path='auth' element={<AuthBase/>}>
              <Route path='login' element={<Login/>}/>
              <Route path='register' element={<Register/>}/>
              
          </Route>

          <Route path='/forgetpassword' element={<ForgetPasswordPage/>} />
          
          <Route exact path='/adminleadgen' Component={LeadgenAdminPage}/>
          <Route exact path='/firstcompany' Component={FirstCompany}/>
          <Route path='' element={<BalancePopupProvider onChangeLang={handleInitLang}/>}>
            <Route path='' element={<Navigate to='/main'/>}/>
              <Route exact path='/settings/:companyId/' element={<AddSocialNetwork onChangeLang={handleInitLang} />}/>
              <Route exact path='/posting/:companyId/' element={
                <AddPostContextProvider>
                  <AddPostMenu/>
                </AddPostContextProvider>
              }/>
              <Route exact path='/paymentDone/:paymentId/:companyId' Component={ConfirmPaymentPage}/>
              <Route exact path='/MyCompanys' Component={MyCompanys}/>
              <Route exact path='/main/:companyId?' Component={MainPage}/>
              <Route exact path='/boosting/:companyId/' Component={BoostingMenu}/>
              <Route exact path='/smarttraffic/:companyId/' Component={SmartTrafficPage}/>
              <Route exact path='/leadgen/:companyId' Component={LeadgenPage}/>
              <Route exact path='/ShortContent' Component={ShortContent}/>
              <Route exact path='/Tariffs' Component={Tariffs}/>
              <Route exact path='/balance/:companyId/' Component={AddBalancePage}/>
              <Route exact path='/coverage/:companyId/' Component={CoveragePage}/>
              <Route exact path='/transaction/:companyId/' Component={TransactionPage}/>
              <Route exact path='/PostponedPosts/:companyId/' Component={PostponedPostPage}/>
              <Route exact path="/MyCompanys/:id/*" element={
                <div>
                  <h1>page</h1>
                </div>
              }/>
          </Route>

          <Route path='*' element={
            <div>
              <h1>404</h1>
            </div>
          }/>
        </Routes>
      </Router>
      }
      </IntlProvider>
    </div>
  );
}

export default App;
