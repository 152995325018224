import { useEffect, useState } from 'react';
import styles from './LeadgenAdminPage.module.css'
import EditableText from '../NewPostTextEditCard/EditableText';
import { changeCompanyDesc, getCompanyDesc } from '../../api/addSocialNetwork';
import { notification } from 'antd';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {useIntl} from 'react-intl';
import { changeKeywords, getCompaniesKeyWords } from '../../api/admin';
export default function LeadgenAdminPage() {
    const [companies, setCompanies] = useState([])
    useEffect(() => {
        async function get_companies(){
            const result = await getCompaniesKeyWords()
            if(result.status){
                console.log(result.responce.companies)
                setCompanies(result.responce.companies)
            }else{
                notification.error({
                    message : "Произошла ошибка"
                })
            }
        }
        get_companies()

    }, []) 
    function changeKeywordsHandler(newVal, index){
        let new_companies = companies.map(a => ({...a}))
        new_companies[index].keywords = newVal
        setCompanies(new_companies)
    }
    async function saveKeywordsHandler(index){
        const result = await changeKeywords(companies[index].id, {'keywords' : companies[index].keywords})
        if(result.status){
            notification.success({
                message : 'Изменено'
            })
        }else{
            notification.error({
                message : "Произошла ошибка"
            })
        }
    }
    return (
        <div className={styles.container} style={{width: "100%"}}>
            <table>
                {companies.map((company, index) => (
                    <tr className={styles.goodtr}>
                        <td>
                            {company.owner}
                        </td>
                        <td>
                            {company.name}
                        </td>
                        <td>
                            <EditableText value={company.keywords} changeHandler={(newVal) => changeKeywordsHandler(newVal, index)}
                             saveHandler={() => saveKeywordsHandler(index)}/>
                        </td>
                    </tr>
                ))}
               
            </table>
        </div>
    );
}
