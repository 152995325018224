import './AddSNLeadgenBlock.css';
import SNIconInf from '../../SNIcon/SNIconWithInf';
import { Link, useParams } from 'react-router-dom';
import InfoButton from '../../InfoButton/InfoButton';
import InfoPopup from '../../InfoPopup/InfoPopup';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

var needSN = ['vk', 'tg', 'inst', 'tw']

export default function AddSNPostingBlock({postingData}) {
    const { companyId } = useParams()
    const [infoPopupOpen, setInfoPopupOpen] = useState(false)
    const intl = useIntl()
    return (
        <div className='block_wrap'>
            <div className='block_name'>
                <p><FormattedMessage id='posting'/></p>
    
                <InfoPopup buttonLink={'posting'} buttonText={intl.formatMessage({id: 'go_to_posting'})} title={intl.formatMessage({id: 'posting'})} onInfoPopupOpen={setInfoPopupOpen} infoPopupOpen={infoPopupOpen}><FormattedMessage id='posting_text' /> </InfoPopup>
                <InfoButton  onInfoPopupOpen={setInfoPopupOpen} />
            </div>
            < div className='block_cards_posting'>
                {needSN.map(sn=><SNIconInf social_network={sn} value={postingData[sn] || 0}/>)}
                
            </div>
        </div>
        // <div className='col'>
        //     <div className='add_sn_top_block'>
        //         <Link className='default_link' to={`/addpost/${companyId}`}>
        //             <div className="post_block_inf">
        //                 <div className='add_sn_text_main'>
        //                     Posting 
        //                 </div>
        //                 <div className='posting_block_wrap'>
        //                     {needSN.map((sn) => (
        //                         <SNIconInf social_network={sn} is_green={sn in postingData}
        //                          value={sn in postingData ? postingData[sn] : 0}/>
        //                     ))}
        //                 </div>
        //             </div>
        //         </Link>
        //     </div>
        // </div>
    );
}