import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import PanelHomeIcon from '../../assets/icons/panel_home';
import PanelPostingIcon from '../../assets/icons/panel_posting';
import './index.css';
import { useEffect, useState } from 'react';
import BoostingPanelIcon from '../../assets/icons/panel_boosting';
import CoveragePanelIcon from '../../assets/icons/panel_coverage';
import BalancePanelIcon from '../../assets/icons/panel_balance';
import BonusPanelIcon from '../../assets/icons/panel_bonus';
import SettingsPanelIcon from '../../assets/icons/panel_settings';
import IconAnimated from '../Icon/IconAnimated';
import { useIntl } from 'react-intl';


const iconHome = require('../../assets/animatedIcons/homeIcon.json')
const iconPosting = require('../../assets/animatedIcons/posting.json')
const iconBoosting = require('../../assets/animatedIcons/boosting.json')
const iconCoverage = require('../../assets/animatedIcons/coverage.json')
const iconLeadgen = require('../../assets/animatedIcons/leadgen.json')
const iconBalance = require('../../assets/animatedIcons/balance.json')
const iconSettings = require('../../assets/animatedIcons/settings.json')



export const LeftPanel = ({leftWidth, MIN_WIDTH, MAX_WIDTH}) => {
    const [showText, setShowText] = useState(true)
    useEffect(()=> {
        if(leftWidth == MAX_WIDTH){
            setTimeout(()=> setShowText(true), 100)
        }else{
            setShowText(false)
        }
    }, [leftWidth])
    const location = useLocation()
    const {companyId} = useParams()
    const navigate = useNavigate()
    const intl = useIntl()

    var menu = [
        {icon : iconHome, name : intl.formatMessage({id: 'home_page'}), path : 'main'},
        {icon : iconPosting, name : intl.formatMessage({id: 'posting'}), path : 'posting'},
        {icon: iconBoosting, name: 'SmarTraffic', path: 'smarttraffic'},
        {icon : iconLeadgen, name : intl.formatMessage({id: 'leadgene'}), path : 'leadgen'},
        {icon : iconCoverage, name : intl.formatMessage({id: 'coverage'}), path : 'coverage'},
        {icon : iconBalance, name : intl.formatMessage({id: 'balance'}), path : 'balance'},
        // {icon : BonusPanelIcon, name : 'Бонус', path : 'bonus'},
        {icon : iconSettings, name : intl.formatMessage({id: 'settings'}), path : 'settings'},
    
    
    ]

    const is_active = (path)=>location.pathname.split('/')[1] === path
    return(
        <>  
            <div className='left_panel_content'>
                <table className='menu_table'>
                    {/* <div style={{width: MIN_WIDTH}} className='left_panel_icons'> */}
                    {menu.map((p)=> (
                        <tr onClick={()=>navigate(`/${p.path}/${companyId || ''}`)} style={{height: 43, cursor: 'pointer'}}>
                            <IconAnimated icon={p.icon} title={leftWidth != MIN_WIDTH && p.name} isNow={is_active(p.path)}/>
                        </tr>                    
                    ))}
                    {/* </div> 
                    {leftWidth != MIN_WIDTH ? (
                        <div className='left_panel_text'>

                        </div>
                    ) : ''} */}
                </table>
               
            </div>
        </>
        
        )
}