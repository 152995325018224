import React from 'react';
import './InputText.css';
import IconAnimated from '../Icon/IconAnimated';
import { useRef } from 'react';
import { Player } from '@lordicon/react';

export default function InputText({name, changeHandlerValue = () => {},
    changeHandler = () => {}, wide=false, placeholer="", labelText,
    type='text', defaultValue, IconComp, customClassname, animatedIcon}) {

      const playerRef = useRef(null);
  return (
    <div className={'text_input_wrap '+ (wide ? "wide " : "")}>
        {labelText ? 
          <label>
            {labelText}
          </label>
          :
          <></>
        }
        <div className='input_icon'>
          {IconComp && 
          <IconComp height={14} width={14}/>
          }
          {
            animatedIcon && 
          
            <Player
              ref={playerRef}
              size={28}
              icon={animatedIcon}
              colorize = {'white'}
                stroke={'bold'}
            />
          
          }
        </div>
        <input onClick={() => !playerRef.current?.isPlaying ? playerRef.current?.playFromBeginning() : () => {} } placeholder={placeholer} className={'text_input ' 
         + (IconComp || animatedIcon  ? "hasicon " : '') + customClassname}
        onChange={(e) => {changeHandler(name, e.target.value);changeHandlerValue(e.target.value)}}
        type={type} defaultValue={defaultValue}/>
    </div>
  );
}
