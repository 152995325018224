import './GreenGrayBox.css';
import React from 'react';

export default function GreenGrayBox({clickHandler = () => {}, is_green, children,
 padding="10px", borderRadius="9px", styles={}}) {
    return (
        <div style={{...styles, padding : padding, borderRadius : borderRadius}}
         onClick={clickHandler}
         className={'box_wrap ' + (is_green ? 'green' : '')}>
            {children}
        </div>
    );
}
