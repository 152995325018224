import { PostponedPostCard } from "../PostponedPostCard/PostponedPostCard";
import { sendRequest } from "../../utils/requests";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import ClipLoader from "react-spinners/ClipLoader";
export function PostponedPostPage() {
    const {companyId} = useParams()
    const [postponedPosts, setPostponedPosts] = useState()

    useEffect(() => {
        async function getPosts() {
           return await sendRequest('/db/getposts', 'POST', {'company_id': companyId} )
        }

        console.log(getPosts().then(data => setPostponedPosts(data.responce['accounts'])))
    }, [])

    function onHandleDeleted(id) {

        setPostponedPosts((prev) => prev.filter((el) => el['id'] != id) )
    }


    console.log(postponedPosts)
    const newData = {}

    if (postponedPosts == undefined) {
        return (
        <div style={{width: '100%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ClipLoader color='grey'/></div>)
    }

    
    return (
        <>
        <h1 style={{fontFamily: 'Futura PT', marginLeft: 10, color: '#051661'}}><FormattedMessage id='delayed_post' /></h1>
        <div style={{display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap', padding: '20px'}}>

            {
                postponedPosts ? postponedPosts.length != 0 ?
                postponedPosts.map(el => <PostponedPostCard post={el} onHandleDeleted={onHandleDeleted}/> )
                 
                 : <p style={{fontFamily: 'Futura PT', fontWeight: 500, fontSize: '1.5rem', color: '#051661'}}><FormattedMessage id="empty_post" /></p>
                 : null
            }
            
        </div>
        </>
    )
}