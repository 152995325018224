import './AddSNLeadgenBlock.css';
import SNIconDownload from '../../SNIcon/SNIconDownload';
import { getAccounts } from '../../../api/addSocialNetwork';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import InfoButton from '../../InfoButton/InfoButton';
import InfoPopup from '../../InfoPopup/InfoPopup';
import { FormattedMessage, useIntl } from 'react-intl';

var needSN = ['vk', 'tg']

export default function AddLeadgenBlock({leadgenData}) {
    const [infoPopupOpen, setInfoPopupOpen] = useState(false)
    const intl = useIntl()
    return (
        <div className='block_wrap'>
            <div className='block_name'>
                <p><FormattedMessage id='leadgene'/></p>
                <InfoButton onInfoPopupOpen={setInfoPopupOpen}/>
                <InfoPopup infoPopupOpen={infoPopupOpen} onInfoPopupOpen={setInfoPopupOpen}  buttonLink={'leadgen'} buttonText={intl.formatMessage({id: 'go_to_leadgen'})}  title={intl.formatMessage({id: 'leadgene'})}><FormattedMessage id='leadgen_text' /></InfoPopup>
            </div>
            < div className='block_cards_leadgen'>
                {needSN.map(sn=><SNIconDownload social_network={sn}
                                is_green={leadgenData[sn]['total']} value={leadgenData[sn]['total']}
                                unread={leadgenData[sn]['unread']}
                                />)}
                
            </div>
        </div>
        /* <div className='col'>
            <div className='add_sn_top_block'>
                <div className="lead_block_inf">
                    <div className='cont_head'>
                        <div className='add_sn_text_main'>
                            Лидген
                        </div>
                    </div>
                    <div className='posting_block_wrap'>
                        {needSN.map((sn) => {
                            return <SNIconDownload social_network={sn}
                                is_green={leadgenData[sn]['total']} value={leadgenData[sn]['total']}
                                unread={leadgenData[sn]['unread']}
                                />
                        })}
                    </div>
                </div>
            </div>
        </div> */
    );
}