import React, { useEffect, useState } from 'react';
import './AddBalancePage.css'
import { FormattedMessage } from 'react-intl';

export default function BonusVariant({sum, bonus, variantSelected}) {
    return (
        <div className='bonus_variant_wrap'>
            <div className='bonus_info_texts'>
                <div className='bonus_main_text'>
                    {sum}₽
                </div>
                <div className='bonus_secondary_text'>
                    <FormattedMessage id='bonus' /> {bonus}%
                </div>
            </div>
            
            <button className='bonus_variant_button' onClick={() => variantSelected(sum, bonus)}>
            <FormattedMessage id='use' />
            </button>

        </div>
    );
}
