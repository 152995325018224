import { useState, useCallback } from "react";

export const useCreateAddPostContext = function(props) {
    
    //active panel's id
    const [socialNetworksList, _setSocialNetworksList] = useState([])
    const [setOfSN, _setSetOfSN] = useState(new Set([]))
    const setSocialNetworksList = useCallback((newValues) => {
        _setSocialNetworksList(newValues)
      }, []);
    const setSetOfSN = useCallback((newValues) => {
        _setSetOfSN(newValues)
      }, []);
    return {
        socialNetworksList,
        setSocialNetworksList,
        setOfSN,
        setSetOfSN,
    };
  }