import styles from './AddInputs.module.css'
import { useState, useRef } from 'react'


export function AddInputs() {
    const inputRef = useRef()
    const [inputWidth, setInputWidth] = useState()


    
    const inputArr = [
        {
          type: "text",
          id: 1,
          value: "",
          width: 150
        }
      ];

      const [arr, setArr] = useState(inputArr);
    
      
    
      const addInput = () => {
        setArr(s => {
          return [
            ...s,
            {
              type: "text",
              value: "",
              width: 150
            }
          ];
        });
      };
    
      const handleChange = e => {
        e.preventDefault();
    
        const index = e.target.id;
        handleWidth(index)

        setArr(s => {
          const newArr = s.slice();
          newArr[index].value = e.target.value;
          newArr[index].width = e.target.value.length < 13 ? 150 : e.target.value.length*9+60
          console.log(newArr[index].width)
    
          return newArr;
        });

        
    }

    function deleteInput(id) {
        const newArray = arr.filter(el => arr.indexOf(el) != id)

        setArr(newArray)

    }

    function handleWidth(i) {
        const inp = arr[i]
        const len = inp.value.length
        if (len > 13) {
            return len*10 + 30
            
        } else {
            return 150
        }
        
    }



    return (

        <div className={styles.container}>
            
            {arr.map((item, i) => {
        return (
        <div ref={inputRef} className={styles.input} style={{width: item.width}}>
            <input
                onChange={handleChange}
                value={item.value}
                id={i}
                type={item.type}
                size="40"
                ref={inputRef}
                
            />

            <span className={styles.minusButton} onClick={() => deleteInput(i)}>&times;</span>
        </div>
        );
      })}
      <span className={styles.button} onClick={addInput}>+</span>
        </div>
        
    )
}