import './ParamsForSN.css';
import InputText from '../InputText/InputText'
import SNinfoRUS from '../../constants/social_networks_info.json'
import SNinfoGEO from '../../constants/social_networks_info_geo.json'
import SNinfoENG from '../../constants/social_networks_info_eng.json'
import SubmitButton from '../SubmitButton/SubmitButton';
import { useEffect, useRef, useState } from 'react';
import { addSocialNetwork, getSecretId } from '../../api/addSocialNetwork';
import { useParams } from 'react-router-dom';
import { notification } from 'antd';
import {QRCodeSVG} from 'qrcode.react';
import { FormattedMessage, useIntl } from 'react-intl';

export default function ParamsForSN({socialNetwork, closeModal, SNinfo}) {
    const params = useRef({})
    let {companyId} = useParams()
    let [tgSecret, setTgSecret] = useState('')
    let [isLoading, setIsLoading] = useState(true)
    
    const intl = useIntl()
    async function goCheckTgAdded(){
        const result = await addSocialNetwork(socialNetwork, companyId, params.current)
        if(!result.status){
            return
        }
        await closeModal({...result.responce.new_account, 'social_network_title' : socialNetwork})
    }
    useEffect(() => {
        async function getData(){
            if(socialNetwork == 'tg'){
                const res = await getSecretId(companyId, {social_network : socialNetwork})
                if(!res.status){
                    notification.error({
                        message: 'Что-то пошло не так'
                    });
                    return
                }
                const newTgSecret = res['responce']['secret_id']
                params.current['secret_id'] = newTgSecret
                setTgSecret(newTgSecret)
            }
        }
        getData()
        setIsLoading(false)
    }, [socialNetwork])
    useEffect(()=> {
        if(socialNetwork == 'tg' && tgSecret){
            goCheckTgAdded()
            const intervalId = setInterval(goCheckTgAdded, 22000)
            return () => clearInterval(intervalId)
        }
    }, [tgSecret])
    function changeHandler(name, new_value){
        params.current[name] = new_value
    }
    async function paramsSubmit(){

        const result = await addSocialNetwork(socialNetwork, companyId, params.current)
        if(!result.status){
            notification.error({
                message: 'Проверьте, что всё заполнено верно и повторите попытку!'
            });
            return
        }
        await closeModal({...result.responce.new_account, 'social_network_title' : socialNetwork})
    }
    function getParamElement(param, width){
        let cur_params = param.split(';')
        if(cur_params.length === 1){
            return (
                <>
                    {cur_params[0]}:
                    <InputText  changeHandler={changeHandler} name={cur_params[0]}/>
                </>
            )
        }else if(cur_params.length === 2){
            return (
                <>
                    {cur_params[1]}:
                    <InputText  changeHandler={changeHandler} name={cur_params[0]}/>
                </>
            )
        }else if(cur_params.length === 3){
            return (
                <>
                    {cur_params[0]}:
                    <SubmitButton 
                        disabled={isLoading}
                        clickHandler={()=>window.open(cur_params[2] + tgSecret, "_blank",  "_noreferer")}
                        text={cur_params[1]}
                        wide={width ? true : false}/>
                </>
            )
        }
    }

    console.log(socialNetwork)
    return (
        <div className='params_wrap'>
            { socialNetwork !== 'tg' ? 
                <>
                <p>{socialNetwork == 'vk' ? intl.formatMessage({id:'add_vk_text'}) : null}</p>
                    <div style={socialNetwork == 'vk' ? {display: 'flex', justifyContent: 'space-between', gap: '30px'} : null}>
                        {SNinfo[socialNetwork]['fields'].map(field => (
                            <div key={field} className='param_wrap' style={socialNetwork == 'vk' ? {width: '100%'} : null}>
                                {getParamElement(field, socialNetwork == 'vk' ? true : null)}
                            </div>
                        ))}
                    </div>
                    
                    <div style={ socialNetwork == 'vk' ? {width: '100%', display: 'flex', justifyContent: 'center'} : null}>
                        <SubmitButton text={intl.formatMessage({id: 'save'})}  style={{margin: '0'}} clickHandler={paramsSubmit}/>
                    </div>
                </>

            : socialNetwork == 'tg' ?
            <div className='params_tg'>
                
                <div style={{display: 'flex', width: '50%'}}>
                    {SNinfo[socialNetwork]['fields'].map(field => (
                        <div key={field} className='param_wrap' style={{gap: 20}}>
                            {getParamElement(field, '50%')}
                        </div>
                    ))}
                     
                     

                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <p><FormattedMessage id='scan_QR'/></p>
                    <div style={{margin: 'auto', marginTop: '0.5rem'}}>
                        <QRCodeSVG value={'https://t.me/socialitepostingbot?start=' + tgSecret}/>         
                    </div>
                           
                </div>

                
            </div>
            : null
        }
        </div>
    );
}
