import './RegisterVerificationCode.css';
import CenterWindow from '../CenterWindow/CenterWindow';
import InputText from '../InputText/InputText';
import React, { useRef, useState } from 'react';
import SubmitButton from '../SubmitButton/SubmitButton';
import { getAuthToken } from '../../api/api';
import { useIntl } from 'react-intl';

export default function RegisterVerificationCode({submitHandler}) {
  const verificationData = useRef({})
  const intl = useIntl()
  const [waitingForConfirm, setWaitingForConfirm] = useState(false)
  function changeHandler(name, new_value){
    verificationData.current[name] = new_value
  }
  async function submitButtonPressed(){
    setWaitingForConfirm(true)
    await submitHandler(verificationData.current)
    setWaitingForConfirm(false) 
  }
  return (
    <React.Fragment>
        <InputText placeholer={intl.formatMessage({id: 'verification_code'})} name={'verification_code'} changeHandler={changeHandler}/>
        <SubmitButton disabled={waitingForConfirm} clickHandler={submitButtonPressed}
         text={intl.formatMessage({id: 'register_button'})} wide style={{marginLeft: 0}}></SubmitButton>
    </React.Fragment>
  );
}
