import './AddSNLeadgenBlock.css';
import SNIconInf from '../../SNIcon/SNIconWithInf';
import SnIconOnOff from '../../SNIcon/SnIconOnOff';
import { useEffect, useState } from 'react';
import { goSwitchCoverageStatus } from '../../../api/mainPage';
import { useParams } from 'react-router-dom';
import { notification } from 'antd';
import { useBalanceContext } from '../../../contexts/BalanceContextProvider';
import InfoButton from '../../InfoButton/InfoButton';
import InfoPopup from '../../InfoPopup/InfoPopup';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';

var needSN = ['vk', 'inst', 'tg', 'tw']

export default function AddCovergeBlock({coverageDataInit}) {
    const {companyId} = useParams()
    const [coverageData, setCoverageData] = useState({})
    const {setNoBalancePopupOpen} = useBalanceContext()
    const intl = useIntl()
  const [infoPopupOpen, setInfoPopupOpen] = useState(false)

    useEffect(() => {
        setCoverageData(coverageDataInit)
    }, [coverageDataInit])
    async function switchCoverageStatus(socialNetwork){
        const result = await goSwitchCoverageStatus({'social_network' : socialNetwork,
             'cur_status' : coverageData[socialNetwork]['status']}, companyId,
             ()=>setNoBalancePopupOpen(true))
        if(!result.status){
            notification.error({message: intl.formatMessage({id: 'smth_went_wrong'})})
            return
        }
        setCoverageData((old) => {
            const newInfo = {...old[socialNetwork]}
            newInfo['status'] = result['responce']['coverage_status']
            return {...old, [socialNetwork] : newInfo}
        })
    }
    return (
        <div className='block_wrap'>
            <div className='block_name'>
                <FormattedMessage id='coverage' />
                <InfoButton onInfoPopupOpen={setInfoPopupOpen}/>
                <InfoPopup infoPopupOpen={infoPopupOpen} onInfoPopupOpen={setInfoPopupOpen}  buttonLink={'coverage'} buttonText={intl.formatMessage({id: 'go_to_coverage'})}  title={intl.formatMessage({id: 'coverage'})}><FormattedMessage id='coverage_text' /></InfoPopup>
            </div>
            < div className='block_cards_coverage'>
                {needSN.map((sn)=>(
                            <SnIconOnOff social_network={sn}
                             is_green={(sn in coverageData) && coverageData[sn]['status'] }
                             canTurnOn={sn in coverageData}
                             value={sn in coverageData ? coverageData[sn]['done'] : 0} 
                             onSwitch={switchCoverageStatus}/>
                        ))}
                
            </div>
        </div>
        // <div className='col'>
        //     <div className='add_sn_top_block'>
        //         <div className="post_block_inf">
        //             <div className='add_sn_text_main'>
        //                 Охваты
        //             </div>
        //             <div className='cover_block_wrap'>
        //                 {needSN.map((sn)=>(
        //                     <SnIconOnOff social_network={sn}
        //                      is_green={(sn in coverageData) && coverageData[sn]['status'] }
        //                      canTurnOn={sn in coverageData}
        //                      value={sn in coverageData ? coverageData[sn]['done'] : 0} 
        //                      onSwitch={switchCoverageStatus}/>
        //                 ))}
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}