import { Link, useParams } from 'react-router-dom';
import './BoostingMenu.css'
import { useEffect, useRef, useState } from 'react';
import SNIcon from '../SNIcon/SNIcon';
import SNIconWithoutInf from '../SNIcon/SNIconWithoutInf';
import backArrow from '../../assets/icons/backarrow.svg'
import GreenGrayBox from '../GreenGrayBox/GreenGrayBox';
import InputText from '../InputText/InputText';
import SubmitButton from '../SubmitButton/SubmitButton';
import { startBoosting } from '../../api/boosting';
import { Button, notification } from 'antd';
import { useBalanceContext } from '../../contexts/BalanceContextProvider';
import { VKIconWhite } from '../../assets/icons/VKIcon';
import { FacebookIcon } from '../../assets/icons/Facebook';
import { TwitterIcon } from '../../assets/icons/TwitterIcon';
import { TelegramIcon } from '../../assets/icons/TelegramIcon';
import { InstagramIcon } from '../../assets/icons/InstagramIcon';
import Slider from '@mui/material/Slider';
import PropTypes from 'prop-types';
import { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import InfoButton from '../InfoButton/InfoButton';
import InfoPopup from '../InfoPopup/InfoPopup';
import { FormattedMessage, useIntl } from 'react-intl';
import ClipLoader from 'react-spinners/ClipLoader';



const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: 'rgba(0, 48, 255, 1)',
    height: 5,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
      height: 27,
      width: 27,
      backgroundColor: '#fff',
      border: '1px solid currentColor',
      '&:hover': {
        boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
      },
      '& .airbnb-bar': {
        height: 9,
        width: 1,
        backgroundColor: 'currentColor',
        marginLeft: 1,
        marginRight: 1,
      },
    },
    '& .MuiSlider-track': {
      height: 3,
    },
    '& .MuiSlider-rail': {
      color: 'rgba(0, 48, 255, 1)',
      opacity: theme.palette.mode === 'dark' ? undefined : 1,
      height: 3,
    },
  }));
  
  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
   
      </SliderThumb>
    );
  }
  
  AirbnbThumbComponent.propTypes = {
    children: PropTypes.node,
  };


export default function BoostingMenu({}) {
  const intl = useIntl()
  var boostingSNList = ['vk', 'tg', 'tw', 'inst', 'vkgroup']
    var funcList = [intl.formatMessage({id: 'subscribers'}), intl.formatMessage({id: 'likes'}), intl.formatMessage({id: 'views'}), intl.formatMessage({id: 'comments'})]
    const {companyId} = useParams()
    const {prices, balance} = useBalanceContext()
    const [selectedSN, setSelectedSN] = useState(boostingSNList[0])
    const [selectedFunc, setSelectedFunc] = useState(funcList[0])
    const [boostType, setBoostType] = useState('обычная')
    const [price, setPrice] = useState('--')
    const [cnt, setCnt] = useState(0)
    const [infoPopupOpen, setInfoPopupOpen] = useState(false)
    

    

    console.log(cnt)
    const params = useRef({link : '', count : 0})
    useEffect(()=>{
        if(!prices['boost'])return
        setPrice((prices['boost'][selectedSN][selectedFunc] * cnt))
    }, [prices, selectedFunc, selectedSN, cnt])
    function paramChanged(name, newValue){
        if(name == 'count'){
            setCnt(newValue)
        }
        params.current[name] = newValue
    }
    async function goBoost(){
        params.current['func'] = selectedFunc
        params.current['social_network'] = selectedSN
        const result = await startBoosting(params.current)
        if(!result.status){
            notification.error({message : "Проверьте корректность заполнения полей"})
        }else{
            notification.success({message : "Отправлено! Ожидайте результат!"})
        }
    }

    if (!prices) {
      return (
      <div style={{width: '100%', height: '65vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <ClipLoader color='grey'/>
      </div>)
  }



    return (
        <>
        <div className='container-fluid'>
            <div className='choose_sn_block_wrap'>
                <p style={{lineHeight: '30px'}}><FormattedMessage id='select_social_network' /></p>
                <div className='choose_sn_block'>
                    <VKIconWhite color={ selectedSN === 'vk' ? 'white' : 'rgba(62, 80, 164, 1)'} onClick={() =>{ setSelectedSN('vk')}}/>
                    <TwitterIcon color={ selectedSN === 'tw' ? 'white' : 'rgba(62, 80, 164, 1)'} onClick={() =>{ setSelectedSN('tw')}}/>
                    <TelegramIcon color={ selectedSN === 'tg' ? 'white' : 'rgba(62, 80, 164, 1)'} onClick={() =>{ setSelectedSN('tg')}}/>
                    <InstagramIcon color={ selectedSN === 'inst' ? 'white' : 'rgba(62, 80, 164, 1)'} onClick={() =>{ setSelectedSN('inst')}}/>
                </div>
            </div>
        <div style={{flexDirection: 'row', display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '1rem'}}>
            

            <div className='choose_func_block_wrap'>
                {funcList.map(f => (
                    <GreenGrayBox padding={".5rem 0"}
                    is_green={selectedFunc==f}
                    clickHandler={() => setSelectedFunc(f)}
                    styles={{maxWidth : "22%",
                     width: "100%", textAlign : "center"}}>
                        {f}
                    </GreenGrayBox>
                ))}
            </div>
            </div>
            <input  onChange={paramChanged} className='input' style={{marginTop: '2%', marginBottom: '1%'}}  placeholder={intl.formatMessage({id: 'post_link'})}/>

            <p style={{fontFamily: 'Futura PT', fontWeight: '500', fontSize: '1.5em', marginTop: '1%', color: 'rgba(0, 17, 93, 1)'}}> <FormattedMessage id='post_quantity' /></p>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <input onChange={(event) => setCnt(Number(event.target.value))} className='input' style={{margin: 0, width: '48%'}} placeholder={intl.formatMessage({id: 'post_quantity_input'})}/>

        <div style={{width: '47%', marginTop: '10px' }} >
            <div>
            <AirbnbSlider
        valueLabelDisplay="auto"
        slots={{ thumb: AirbnbThumbComponent }}
        defaultValue={50}
        aria-label="Restricted values"
        value={cnt}
        min ={ 0}
        max={10_000}
        onChange={(event, value) => setCnt(value)}
      />

            </div>
            </div>
            </div>

            <div className='slider_block'>
        
            </div>
            <InfoPopup infoPopupOpen={infoPopupOpen} onInfoPopupOpen={setInfoPopupOpen}  title={'Образование цены'}>Цена</InfoPopup>

            <div className='price_block'>
<div style={{display: 'flex', width: '25%', alignItems: 'center'}}>
                  <p className='price_text' style={{width: '90%'}}><FormattedMessage id='total' />: {price/100} TKN</p>
                  <InfoButton onInfoPopupOpen={setInfoPopupOpen}/>
                  </div>
                <p className='balance_text'><FormattedMessage id='balance' />: {balance/100} TKN</p>
                <SubmitButton withBorder={true} disabled={balance<price} clickHandler={goBoost} text={intl.formatMessage({id: 'boost'})} style={{marginLeft: '10%'}} customClassName='submit_button'/>
            </div>

            
            
        </div>
        </>
    );
}
